  @import "../settings";
  //Desktop Start-----------------------------------------------------------------------------
  @if ($isDesktop) {
    //---------------------------------------------------------------------------------------
    //User Entrance Start
    //---------------------------------------------------------------------------------------
    .user-entrance-bc {
      padding: 16px;
      background-color: #005b55;
    }
    .usr-ent-content-bc {}
    .usr-ent-text-bc {
      display: block;
      font-weight: normal;
      font-size: 20px;
      color: #ffc854;
      text-align: center;
      line-height: 26px;
      &:first-letter {
        text-transform: capitalize;
      }
    }
    .usr-ent-form-bc {
      padding-top: 36px;
    }
    .usr-ent-form-item-bc {
      margin-bottom: 14px;
      &.forgot-password {
        text-align: center;
      }
    }
    .usr-ent-form-actions-holder-bc {
      display: table;
      table-layout: fixed;
      width: 100%;
      border-radius: 5px;
      overflow: hidden;
    }
    .usr-ent-form-error-holder-bc {
      margin-bottom: 14px;
    }
    .usr-ent-form-error-bc {
      display: inline-block;
      vertical-align: top;
      font-weight: normal;
      font-size: 12px;
      color: #db1c30;
    }
    .usr-ent-form-action-item-bc {
      display: table-cell;
      vertical-align: top;
    }
    .usr-ent-form-action-bc {
      display: block;
      width: 100%;
      height: 40px;
      border: none;
      outline: none;
      box-sizing: border-box;
      background-color: #ffc854;
      transition: background-color $default-transition-duration ease;
      font-family: inherit;
      font-weight: bold;
      font-size: 18px;
      color: #000;
      text-transform: uppercase;
      cursor: pointer;
      &.login {}
      &.previous {}
      &.next {}
      &.register {}
      &[disabled] {
        opacity: 0.7;
        pointer-events: none;
      }
      &:hover {
        background-color: darken(#ffc854, 5%);
      }
    }
    .usr-ent-forgot-password-bc {
      display: inline-block;
      vertical-align: top;
      font-weight: normal;
      font-size: 14px;
      color: #9e9e9e;
      transition: color $default-transition-duration ease;
      cursor: pointer;
      &:first-letter {
        text-transform: capitalize;
      }
      &:hover {
        color: #fff;
      }
    }
    //---------------------------------------------------------------------------------------
    //User Entrance end
    //---------------------------------------------------------------------------------------
  }
  //Desktop End-------------------------------------------------------------------------------
  //Mobile Start------------------------------------------------------------------------------
  @if ($isMobile) {}
  //Mobile End--------------------------------------------------------------------------------
  @if ($hasSportsBook) {
    //---------------------------------------------------------------------------------------
    //Markets Start
    //---------------------------------------------------------------------------------------
    .market-group-holder-bc {
      display: table;
      table-layout: fixed;
      width: 100%;
      &.ext-32 {
        > .market-group-item-bc {
          height: 32px;
        }
      }
      &.ext-24 {
        > .market-group-item-bc {
          height: 24px;
        }
      }
    }
    .market-group-item-bc {
      display: table-cell;
      vertical-align: top;
      height: 100%;
      [dir=ltr] & {
        border-right: 1px solid transparent;
      }
      [dir=rtl] & {
        border-left: 1px solid transparent;
      }
      &:last-child {
        border: none;
      }
    }
    //---------------------------------------------------------------------------------------
    //Markets End
    //---------------------------------------------------------------------------------------
//-------------------------------------------------//
    //---------------------------------------------------------------------------------------
    //Common Start
    //---------------------------------------------------------------------------------------
    .quick-bet-bet-cloud-holder-bc {
      display: table;
      table-layout: fixed;
      width: 100%;
    }
    .qbbc-cell-bc {
      display: table-cell;
      vertical-align: top;
      [dir=ltr] & {
        border-right: 1px solid transparent;
      }
      [dir=rtl] & {
        border-left: 1px solid transparent;
      }
      &:last-child {
        border: none;
      }
    }
    .qbbc-cell-content-bc{
      height: 30px;
      vertical-align: middle;
      position: relative;
      background-color: $default-skin-palette-5;
      background-color: var(--skin-palette-5);
      [dir=ltr] & {
        padding: 0 40px 0 8px;
      }
      [dir=rtl] & {
        padding: 0 8px 0 40px;
      }
    }
    .qbbc-switcher-holder-bc{
      display: inline-block;
      vertical-align: top;
      width: 30px;
      height: 100%;
      position: absolute;
      top: 0;
      [dir=ltr] & {
        right: 8px;
      }
      [dir=rtl] & {
        left: 8px;
      }
      > .switcher-bc{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
    .qbbc-ct-title-bc{
      display: block;
      height: 100%;
      font-weight: normal;
      font-size: 12px;
      color: $default-text-palette-18;
      color: var(--text-palette-18);
      line-height: 30px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      &:first-letter {
        text-transform: capitalize;
      }
    }
    .qbbc-ct-icon-bc{
      display: inline-block;
      vertical-align: middle;
      font-size: 16px;
      line-height: 30px;
      color: $default-text-palette-18;
      color: var(--text-palette-18);
    }
    //---------------------------------------------------------------------------------------
    //Common Start
    //---------------------------------------------------------------------------------------
  }
