  @import "settings";

  //Desktop Start-----------------------------------------------------------------------------
  @if ($isDesktop) {
    .react-datepicker-wrapper,
    .react-datepicker__input-container{
      width: 100%;
    }
  }
  //Desktop End-------------------------------------------------------------------------------

  //Mobile Start------------------------------------------------------------------------------
  @if ($isMobile) {

  }
  //Mobile End--------------------------------------------------------------------------------
