  @import "../settings";
  //Desktop Start-----------------------------------------------------------------------------
  //Desktop End-------------------------------------------------------------------------------
//-------------------------------------------------//
  //Desktop Or Mobile Start-------------------------------------------------------------------
  @if ($isMobile or $isDesktop) {
    //-------------------------------------------------------
    //Input Controls Start-----------------------------------
    //-------------------------------------------------------
      $input-height: 52px;
      $text-area-height: 160px;
    .form-control-bc {
      min-height: $input-height;
      position: relative;
      &.has-icon {
        .form-control-input-bc {
          [dir=ltr] & {
            padding-right: 24px;
          }
          [dir=rtl] & {
            padding-left: 24px;
          }
        }
      }
      &.default {}
      &.file {
        .form-control-label-bc {
          cursor: pointer;
        }
        .form-control-input-bc {
          display: none;
        }
      }
      &.select {
        .form-control-icon-holder-bc {
          pointer-events: none;
        }
      }
      &.focused,
      &.filled {
        .form-control-title-bc {
          top: -2px;
          font-size: 12px;
          line-height: 14px;
        }
      }
      &.focused {
        .form-control-input-stroke-bc {
          &:after {
            width: 100%;
            background-color: $hero-color-extended;
            background-color: var(--hero-color-extended);
          }
        }
        .form-control-icon-bc {
          &.bc-i-arrow-drop-down-bottom {
            transform: rotateX(180deg);
            color: $hero-color;
            color: var(--hero-color);
          }
        }
      }
      &.filled {}
      &.valid {}
      &.invalid {
        .form-control-input-stroke-bc {
          &:after {
            width: 100%;
            background-color: $status-color-fail;
            background-color: var(--status-color-fail);
            box-shadow: 0 2px 4px 0 $status-color-fail;
            box-shadow: 0 2px 4px 0 var(--status-color-fail);
          }
        }
      }
    }
    .form-control-label-bc {
      display: block;
      position: relative;
      &.inputs {
        height: $input-height;
      }
      &.textareas {
        height: $text-area-height;
        .form-control-input-bc {
          resize: none;
        }
      }
    }
    .form-control-input-bc {
      display: block;
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      box-sizing: border-box;
      background-color: transparent;
      font-family: inherit;
      font-weight: normal;
      font-size: 14px;
      color: $text-color-white;
      color: var(--text-color-white);
      transition: color $default-transition-duration ease;
    }
    .form-control-fine-name-bc {
      display: block;
      width: 100%;
      height: 100%;
      font-weight: normal;
      font-size: 12px;
      color: $text-color-white;
      color: var(--text-color-white);
      line-height: $input-height;
    }
    .form-control-select-bc {
      display: block;
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      box-sizing: border-box;
      background-color: transparent;
      font-family: inherit;
      font-weight: normal;
      font-size: 12px;
      color: $text-color-white;
      color: var(--text-color-white);
      transition: color $default-transition-duration ease;
      appearance: none;
      [dir=ltr] & {
        padding-right: 18px;
      }
      [dir=rtl] & {
        padding-left: 18px;
      }
      > option {
        background-color: $default-skin-palette-4;
        background-color: var(--skin-palette-4);
        &:hover {
          background-color: $hero-color;
          background-color: var(--hero-color);
          color: $text-color-white;
          color: var(--text-color-white);
        }
      }
    }
    .form-control-textarea-bc {
      display: block;
      width: 100%;
      height: 100%;
      padding: 8px 0;
      resize: none;
      border: none;
      outline: none;
      box-sizing: border-box;
      background-color: transparent;
      font-family: inherit;
      font-weight: normal;
      font-size: 12px;
      color: $text-color-white;
      color: var(--text-color-white);
      transition: color $default-transition-duration ease;
    }
    .form-control-input-stroke-bc {
      display: block;
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      &:before,
      &:after {
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
      &:before {
        width: 100%;
        background-color: $default-skin-palette-16;
        background-color: var(--skin-palette-16);
        transition-property: background-color, opacity;
        transition-duration: $default-transition-duration;
        opacity: 0.5;
      }
      &:after {
        width: 0;
        transition-property: width, background-color;
        transition-duration: $default-transition-duration;
        transform: translate3d(0, 0, 0);
      }
    }
    .form-control-title-bc {
      display: inline-block;
      vertical-align: top;
      max-width: 100%;
      font-size: 12px;
      color: $default-text-palette-13;
      color: var(--text-palette-13);
      line-height: 14px;
      text-transform: capitalize;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      position: absolute;
      top: 12px;
      transition-property: top, font-size, line-height, color;
      transition-duration: $default-transition-duration;
      pointer-events: none;
      user-select: none;
      [dir=ltr] & {
        left: 0;
      }
      [dir=rtl] & {
        right: 0;
      }
    }
    .form-control-icon-holder-bc {
      display: inline-block;
      vertical-align: top;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
      [dir=ltr] & {
        right: 0;
      }
      [dir=rtl] & {
        left: 0;
      }
    }
    .form-control-icon-bc {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      font-size: 12px;
      text-align: center;
      color: $default-text-palette-17;
      color: var(--text-palette-17);
      transition-property: transform, color;
      transition-duration: $default-transition-duration;
      cursor: pointer;
      &:hover {
        color: $default-text-palette-18;
        color: var(--text-palette-18);
      }
    }
    .form-control-message-holder-bc {
      margin-top: 10px;
      animation: form-control-message $default-transition-duration ease;
      @keyframes form-control-message {
        0% {transform: translateY(-50%);}
        100% {transform: translateY(0%);}
      }
    }
    .form-control-message-bc {
      display: block;
      font-weight: normal;
      font-size: 10px;
      color: $status-color-fail;
      color: var(--status-color-fail);
      line-height: 12px;
    }
    //-------------------------------------------------------
    //Input Controls End-------------------------------------
    //-------------------------------------------------------
//-------------------------------------------------//
    //-------------------------------------------------------
    //Checkbox Controls Start--------------------------------
    //-------------------------------------------------------
    .checkbox-control-bc {
      &.checked {
        .checkbox-control-icon-bc {
          color: $text-color-white;
          color: var(--text-color-white);
        }
      }
    }
    .checkbox-control-content-bc {
      display: inline-block;
      vertical-align: top;
      max-width: 100%;
      min-height: 20px;
      position: relative;
      cursor: pointer;
      [dir=ltr] & {
        padding-left: 24px;
      }
      [dir=rtl] & {
        padding-right: 24px;
      }
    }
    .checkbox-control-input-holder-bc {
      display: inline-block;
      vertical-align: top;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 0;
      [dir=ltr] & {
        left: 0;
      }
      [dir=rtl] & {
        right: 0;
      }
    }
    .checkbox-control-input-bc {
      display: none;
    }
    .checkbox-control-icon-bc {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      height: 100%;
      font-size: 10px;
      line-height: 16px;
      text-align: center;
      position: absolute;
      top: 0;
      background: $default-skin-palette-5;
      background: var(--skin-palette-5);
      border-radius: $desktop-border-radius-size-2;
      color: $default-text-palette-17;
      color: var(--text-palette-17);
      transition: color $default-transition-duration ease;
      cursor: pointer;
      [dir=ltr] & {
        left: 0;
      }
      [dir=rtl] & {
        right: 0;
      }
      &:hover {
        color: $hero-color;
        color: var(--hero-color);
      }
      &.bc-i-check-box-unchecked {}
      &.bc-i-check-box-checked {}
    }
    .checkbox-control-text-bc {
      font-size: 14px;
      color: $default-text-palette-15;
      color: var(--text-palette-15);
      line-height: 16px;
    }
    //-------------------------------------------------------
    //Checkbox Controls End----------------------------------
    //-------------------------------------------------------
//-------------------------------------------------//
    //-------------------------------------------------------
    //Radio Controls Start-----------------------------------
    //-------------------------------------------------------
    .radio-control-bc {
      display: inline-block;
      vertical-align: top;
      &.checked {
        .radio-control-icon-bc {
          color: $hero-color;
          color: var(--hero-color);
        }
      }
      ~ .radio-control-bc{
        margin-left: 15px;
      }
    }
    .form-control-title-static-bc{
      width: 100%;
      font-size: 14px;
      color: $default-text-palette-15;
      color: var(--text-palette-12);
      line-height: 18px;
      text-transform: capitalize;
      //white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 10px;
      > a{
        color: $text-color-white;
        color: var(--text-color-white);
        text-decoration: underline;
      }
    }
    .radio-control-content-bc {
      display: inline-block;
      vertical-align: top;
      max-width: 100%;
      min-height: 20px;
      position: relative;
      cursor: pointer;
      [dir=ltr] & {
        padding-left: 24px;
      }
      [dir=rtl] & {
        padding-right: 24px;
      }
    }
    .radio-control-input-holder-bc {
      display: inline-block;
      vertical-align: top;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 0;
      [dir=ltr] & {
        left: 0;
      }
      [dir=rtl] & {
        right: 0;
      }
    }
    .radio-control-input-bc {
      display: none;
    }
    .radio-control-icon-bc {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      height: 100%;
      font-size: 20px;
      position: absolute;
      top: 0;
      color: $default-text-palette-17;
      color: var(--text-palette-17);
      transition: color $default-transition-duration ease;
      cursor: pointer;
      [dir=ltr] & {
        left: 0;
      }
      [dir=rtl] & {
        right: 0;
      }
      &:hover {
        color: $hero-color;
        color: var(--hero-color);
      }
      &.bc-i-check-box-unchecked {}
      &.bc-i-check-box-checked {}
    }
    .radio-control-text-bc {
      font-weight: bold;
      font-size: 10px;
      color: $default-text-palette-17;
      color: var(--text-palette-17);
      line-height: 20px;
    }
//-------------------------------------------------//
    //-------------------------------------------------------
    //Radio Controls End-------------------------------------
    //-------------------------------------------------------
//-------------------------------------------------//
    //-------------------------------------------------------
    //Login - Registration Form Controls Holders Start-------
    //-------------------------------------------------------
    .form-controls-group-bc {
      &.date-picker {}
      &.telephone {
        .form-controls-field-bc {
          &.country-code {
            width: 64px;
          }
        }
      }
    }
    .form-controls-fields-bc {
      display: table;
      table-layout: fixed;
      border-collapse: collapse;
      width: 100%;
    }
    .form-controls-field-bc {
      display: table-cell;
      vertical-align: top;
      [dir=ltr] & {
        border-right: 4px solid transparent;
      }
      [dir=rtl] & {
        border-left: 4px solid transparent;
      }
      &:last-child {
        border: none;
      }
    }
    .form-controls-holder-bc {}
    //-------------------------------------------------------
    //Login - Registration Form Controls Holders End---------
    //-------------------------------------------------------
  }
  //Desktop Or Mobile End---------------------------------------------------------------------
//-------------------------------------------------//
  //Mobile Start------------------------------------------------------------------------------
  @if ($isMobile) {
    .select-option-holder-bc {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
    }
    .select-option-bc {
      display: block;
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      border-radius: 0;
      appearance: none;
      font-family: inherit;
      text-transform: capitalize;
      [dir=ltr] & {
        padding-left: 8px;
        padding-right: 30px;
      }
      [dir=rtl] & {
        padding-right: 8px;
        padding-left: 30px;
      }
      > option {
        color: $text-color-black;
        color: var(--text-color-black);
      }
      &:focus {
        & + .select-option-icon-bc {
          transform: rotateX(180deg);
        }
      }
    }
    .select-option-icon-bc {
      display: inline-block;
      vertical-align: top;
      width: 12px;
      height: 12px;
      font-size: 8px;
      line-height: 12px;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
      transition: transform $default-transition-duration ease;
      [dir=ltr] & {
        right: 9px;
      }
      [dir=rtl] & {
        left: 9px;
      }
    }
    .form-control-input-stroke-bc{
      display: none;
    }
    .form-control-input-bc, .form-control-select-bc, .form-control-fine-name-bc{
      background: $default-skin-palette-5;
      background: var(--skin-palette-5);
      border-radius: $desktop-border-radius-size-2;
      padding: 20px 8px 4px;
    }
    .form-control-fine-name-bc{
      padding-top: 10px;
    }
    .form-control-bc {
    }
    .form-control-input-bc{
      &:read-only{
        pointer-events: none;
        opacity: 0.6;
      }
    }
    .form-control-title-bc{
      font-weight: normal;
      top: 20px;
      background: transparent;
      padding: 0;
      [dir=ltr] & {
        left: 8px;
      }
      [dir=rtl] & {
        right: 8px;
      }
    }
    .form-control-bc.focused, .form-control-bc.filled{
      .form-control-title-bc{
        top: 10px;
      }
    }
    .form-control-icon-holder-bc{
      [dir=ltr] & {
        right: 10px;
      }
      [dir=rtl] & {
        left: 10px;
      }
    }
  }
  //Mobile End--------------------------------------------------------------------------------
