@import 'sass/settings';
@font-face {
  font-family: Roboto;
  font-weight: normal;
  font-style: normal;
  src: local('Roboto'),
  url('/fonts/default/Roboto.woff2#{$version-control}') format('woff2'),
  url('/fonts/default/Roboto.woff#{$version-control}') format('woff'),
  url('/fonts/default/Roboto.ttf#{$version-control}') format('truetype'),
  url('/fonts/default/Roboto.eot#{$version-control}'),
  url('/fonts/default/Roboto.eot#{$version-control}') format('embedded-opentype'),
  url('/fonts/default/Roboto.svg#{$version-control}') format('svg');
  font-display: fallback;
}
@font-face {
  font-family: Roboto;
  font-weight: bold;
  font-style: normal;
  src: local('Roboto Bold'),
  url('/fonts/default/RobotoBold.woff2#{$version-control}') format('woff2'),
  url('/fonts/default/RobotoBold.woff#{$version-control}') format('woff'),
  url('/fonts/default/RobotoBold.ttf#{$version-control}') format('truetype'),
  url('/fonts/default/RobotoBold.eot#{$version-control}'),
  url('/fonts/default/RobotoBold.eot#{$version-control}') format('embedded-opentype'),
  url('/fonts/default/RobotoBold.svg#{$version-control}') format('svg');
  font-display: fallback;
}
