  @import "../dev/sass/constants";
  @import "settings";
  @import "normalize";
  @import "../skins/_global/common/static/fonts/fonts";
  @import "../skins/_global/common/static/icons/general/general_icons";
  @import "../skins/_global/common/static/icons/sports/sport_icons";
  @import "../skins/_global/common/static/flags/flags";
  @import "sport_colors";
  @import "common/form_controls";
  @import "common/elements";
  @import "external_libraries_overrides";
  @import "../dev/sass/_css_variables.scss";
  html {
    font-family: $font-family;
    background-color: $html-bg;
    background-color: var(--html-bg);
  }
  html, body {
    width: 100%;
    min-height: 100%;
  }
  @keyframes theme-view {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  body {
    //animation: $default-transition-duration * 2.5 theme-view ease-in-out;
    //animation-fill-mode: forwards;
    //animation-delay: $default-transition-duration * 3;
    //opacity: 0;
  }
  //Desktop Start-----------------------------------------------------------------------------
  @if ($isDesktop) {
    html {
      min-width: 980px;
      @include mozillaScrollbar();
    }
    ::-webkit-scrollbar {
      width: $scrollbar-width;
    }
    ::-webkit-scrollbar-track {
      background-color: $scrollbar-track-bg;
      background-color: var(--scrollbar-track-bg);
    }
    ::-webkit-scrollbar-thumb {
      background-color: $scrollbar-thumb-bg;
      background-color: var(--scrollbar-thumb-bg);
      border-radius: $scrollbar-thumb-border-radius;
    }
    ::selection {
      background-color: $hero-color;
      color: $text-color-white;
    }
  }
  //Desktop End-------------------------------------------------------------------------------
  //Mobile Start------------------------------------------------------------------------------
  @if ($isMobile) {
    html {
      -webkit-overflow-scrolling: touch;
      -webkit-text-size-adjust: none;
      //user-select: none;
    }
    ::-webkit-scrollbar,
    ::-webkit-scrollbar-track,
    ::-webkit-scrollbar-thumb {
      display: none;
      opacity: 0;
    }
  }
  .empty-b-text-v-bc{
    width: 100%;
    padding: 20px 10px 15px;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    color: $default-text-palette-8;
    color: var(--text-palette-8);
  }
  //Mobile End--------------------------------------------------------------------------------
