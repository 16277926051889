@import 'sass/settings';

$loader-big-width: 120px;
$loader-big-height: $loader-big-width;

$loader-medium-width: 70px;
$loader-medium-height: $loader-medium-width;

$loader-small-width: 40px;
$loader-small-height: $loader-small-width;

.bc-loader {
  position: relative;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 50%;
  border: 4px solid rgba(255, 255, 255, 0.1);
  -webkit-mask: linear-gradient(rgba(0, 0, 0, 0.1), #000000 90%);
  transform-origin: 50% 60%;
  animation: spinner-wiggle 600ms infinite;
  &:before,
  &:after {
    content: "";
    position: absolute;
    margin: -4px;
    box-sizing: inherit;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    opacity: .05;
    border: inherit;
    border-color: transparent;
    animation: spinner-spin 600ms cubic-bezier(0.6, 0.2, 0, 0.8) infinite, spinner-fade 600ms linear infinite;
  }
  &:before {
    border-top-color: $notification-color;
    border-top-color: var(--notification-color);
  }
  &:after {
    animation-delay: $default-transition-duration;
    border-top-color: $hero-color;
  }
  &.big {
    width: $loader-big-width;
    height: $loader-big-height;
    transform: perspective($loader-big-height) rotateX(66deg);
  }
  &.medium {
    width: $loader-medium-width;
    height: $loader-medium-height;
    transform: perspective($loader-medium-height) rotateX(66deg);
  }

  &.small {
    width: $loader-small-width;
    height: $loader-small-height;
    transform: perspective($loader-small-height) rotateX(66deg);
  }
}
@keyframes spinner-spin {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spinner-fade {
  20%, 60% {
    opacity: .1;
  }
  40% {
    opacity: 1;
  }
}
