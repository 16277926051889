@import 'sass/settings';

//Desktop Start-----------------------------------------------------------------------------
@if ($isDesktop) {
  .common-button-bc{
    display: block;
    border: none;
    outline: none;
    font-family: inherit;
    transition-property: background-color, color;
    transition-duration: $default-transition-duration;
    cursor: pointer;
  }
}
//Desktop End-------------------------------------------------------------------------------

//Mobile Start------------------------------------------------------------------------------
@if ($isMobile) {
  .button-disabled {
    //background-color: #4d4d4c !important;
  }
}
//Mobile End--------------------------------------------------------------------------------

