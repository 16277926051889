@import 'sass/settings';

.bc-flag {
  display: inline-block;
  vertical-align: top;
  background: url('/flags/flags.png#{$version-control}') no-repeat 0 0 / 100%;
  width: 16px;
  height: 16px;
  image-rendering: pixelated;
}

.asean {
  background-position: 0 0%;
}

.afghanistan {
  background-position: 0 0.346021%;
}

.africa {
  background-position: 0 0.692042%;
}

.aland {
  background-position: 0 1.038062%;
}

.albania {
  background-position: 0 1.384083%;
}

.alderney {
  background-position: 0 1.730104%;
}

.algeria {
  background-position: 0 2.076125%;
}

.all {
  background-position: 0 2.422145%;
}

.americansamoa {
  background-position: 0 2.768166%;
}

.andorra {
  background-position: 0 3.114187%;
}

.angola {
  background-position: 0 3.460208%;
}

.anguilla {
  background-position: 0 3.806228%;
}

.antiguaandbarbuda {
  background-position: 0 4.152249%;
}

.arableague {
  background-position: 0 4.49827%;
}

.argentina {
  background-position: 0 4.844291%;
}

.armenia {
  background-position: 0 5.190311%;
}

.aruba {
  background-position: 0 5.536332%;
}

.asia {
  background-position: 0 5.882353%;
}

.australia {
  background-position: 0 6.228374%;
}

.austria {
  background-position: 0 6.574394%;
}

.azerbaijan {
  background-position: 0 6.920415%;
}

.bahamas {
  background-position: 0 7.266436%;
}

.bahrain {
  background-position: 0 7.612457%;
}

.bangladesh {
  background-position: 0 7.958478%;
}

.barbados {
  background-position: 0 8.304498%;
}

.basquecountry {
  background-position: 0 8.650519%;
}

.belarus {
  background-position: 0 8.99654%;
}

.belgium {
  background-position: 0 9.342561%;
}

.belize {
  background-position: 0 9.688581%;
}

.benin {
  background-position: 0 10.034602%;
}

.bermuda {
  background-position: 0 10.380623%;
}

.bhutan {
  background-position: 0 10.726644%;
}

.bolivia {
  background-position: 0 11.072664%;
}

.bonairesinteustatiusandsaba {
  background-position: 0 11.418685%;
}

.bosniaherzegovina {
  background-position: 0 11.764706%;
}

.botswana {
  background-position: 0 12.110727%;
}

.bouvet {
  background-position: 0 12.456747%;
}

.brazil {
  background-position: 0 12.802768%;
}

.britishindianoceanterritory {
  background-position: 0 13.148789%;
}

.brunei {
  background-position: 0 13.49481%;
}

.bulgaria {
  background-position: 0 13.84083%;
}

.burkinafaso {
  background-position: 0 14.186851%;
}

.burundi {
  background-position: 0 14.532872%;
}

.caricom {
  background-position: 0 14.878893%;
}

.cis {
  background-position: 0 15.224913%;
}

.cambodja {
  background-position: 0 15.570934%;
}

.cameroon {
  background-position: 0 15.916955%;
}

.canada {
  background-position: 0 16.262976%;
}

.capeverde {
  background-position: 0 16.608997%;
}

.catalonia {
  background-position: 0 16.955017%;
}

.caymanislands {
  background-position: 0 17.301038%;
}

.centralafricanrepublic {
  background-position: 0 17.647059%;
}

.chad {
  background-position: 0 17.99308%;
}

.chile {
  background-position: 0 18.3391%;
}

.china {
  background-position: 0 18.685121%;
}

.chinesetaipei {
  background-position: 0 19.031142%;
}

.christmas {
  background-position: 0 19.377163%;
}

.cocoskeeling {
  background-position: 0 19.723183%;
}

.colombia {
  background-position: 0 20.069204%;
}

.commonwealth {
  background-position: 0 20.415225%;
}

.comoros {
  background-position: 0 20.761246%;
}

.congobrazzaville {
  background-position: 0 21.107266%;
}

.congokinshasa {
  background-position: 0 21.453287%;
}

.cookislands {
  background-position: 0 21.799308%;
}

.costarica {
  background-position: 0 22.145329%;
}

.cotedivoire {
  background-position: 0 22.491349%;
}

.croatia {
  background-position: 0 22.83737%;
}

.cuba {
  background-position: 0 23.183391%;
}

.curacao {
  background-position: 0 23.529412%;
}

.cyprus {
  background-position: 0 23.875433%;
}

.czechrepublic {
  background-position: 0 24.221453%;
}

.denmark {
  background-position: 0 24.567474%;
}

.djibouti {
  background-position: 0 24.913495%;
}

.dominica {
  background-position: 0 25.259516%;
}

.dominicanrepublic {
  background-position: 0 25.605536%;
}

.ecuador {
  background-position: 0 25.951557%;
}

.egypt {
  background-position: 0 26.297578%;
}

.elsalvador {
  background-position: 0 26.643599%;
}

.england {
  background-position: 0 26.989619%;
}

.equatorialguinea {
  background-position: 0 27.33564%;
}

.eritrea {
  background-position: 0 27.681661%;
}

.estonia {
  background-position: 0 28.027682%;
}

.ethiopia {
  background-position: 0 28.373702%;
}

.europe {
  background-position: 0 28.719723%;
}

.fao {
  background-position: 0 29.065744%;
}

.falkland {
  background-position: 0 29.411765%;
}

.faroes {
  background-position: 0 29.757785%;
}

.fiji {
  background-position: 0 30.103806%;
}

.finland {
  background-position: 0 30.449827%;
}

.france {
  background-position: 0 30.795848%;
}

.french-guiana {
  background-position: 0 31.141869%;
}

.frenchsouthernandantarcticlands {
  background-position: 0 31.487889%;
}

.gabon {
  background-position: 0 31.83391%;
}

.galicia {
  background-position: 0 32.179931%;
}

.gambia {
  background-position: 0 32.525952%;
}

.georgia {
  background-position: 0 32.871972%;
}

.germany {
  background-position: 0 33.217993%;
}

.ghana {
  background-position: 0 33.564014%;
}

.gibraltar {
  background-position: 0 33.910035%;
}

.greatbritain {
  background-position: 0 34.256055%;
}

.greece {
  background-position: 0 34.602076%;
}

.greenland {
  background-position: 0 34.948097%;
}

.grenada {
  background-position: 0 35.294118%;
}

.guadeloupe {
  background-position: 0 35.640138%;
}

.guam {
  background-position: 0 35.986159%;
}

.guatemala {
  background-position: 0 36.33218%;
}

.guernsey {
  background-position: 0 36.678201%;
}

.guinea {
  background-position: 0 37.024221%;
}

.guineabissau {
  background-position: 0 37.370242%;
}

.guyana {
  background-position: 0 37.716263%;
}

.haiti {
  background-position: 0 38.062284%;
}

.heardislandandmcdonald {
  background-position: 0 38.408304%;
}

.honduras {
  background-position: 0 38.754325%;
}

.hongkong {
  background-position: 0 39.100346%;
}

.hungary {
  background-position: 0 39.446367%;
}

.iaea {
  background-position: 0 39.792388%;
}

.iho {
  background-position: 0 40.138408%;
}

.iceland {
  background-position: 0 40.484429%;
}

.india {
  background-position: 0 40.83045%;
}

.indonesia {
  background-position: 0 41.176471%;
}

.international {
  background-position: 0 41.522491%;
}

.iran {
  background-position: 0 41.868512%;
}

.iraq {
  background-position: 0 42.214533%;
}

.ireland {
  background-position: 0 42.560554%;
}

.islamicconference {
  background-position: 0 42.906574%;
}

.isleofman {
  background-position: 0 43.252595%;
}

.israel {
  background-position: 0 43.598616%;
}

.italy {
  background-position: 0 43.944637%;
}

.jamaica {
  background-position: 0 44.290657%;
}

.japan {
  background-position: 0 44.636678%;
}

.jersey {
  background-position: 0 44.982699%;
}

.jordan {
  background-position: 0 45.32872%;
}

.kazakhstan {
  background-position: 0 45.67474%;
}

.kenya {
  background-position: 0 46.020761%;
}

.kiribati {
  background-position: 0 46.366782%;
}

.kosovo {
  background-position: 0 46.712803%;
}

.kurdish {
  background-position: 0 47.058824%;
}

.kuwait {
  background-position: 0 47.404844%;
}

.kyrgyzstan {
  background-position: 0 47.750865%;
}

.laos {
  background-position: 0 48.096886%;
}

.latvia {
  background-position: 0 48.442907%;
}

.lebanon {
  background-position: 0 48.788927%;
}

.lesotho {
  background-position: 0 49.134948%;
}

.liberia {
  background-position: 0 49.480969%;
}

.libya {
  background-position: 0 49.82699%;
}

.liechtenshein {
  background-position: 0 50.17301%;
}

.lithuania {
  background-position: 0 50.519031%;
}

.luxembourg {
  background-position: 0 50.865052%;
}

.macao {
  background-position: 0 51.211073%;
}

.macedonia {
  background-position: 0 51.557093%;
}

.madagascar {
  background-position: 0 51.903114%;
}

.malawi {
  background-position: 0 52.249135%;
}

.malaysia {
  background-position: 0 52.595156%;
}

.maldives {
  background-position: 0 52.941176%;
}

.mali {
  background-position: 0 53.287197%;
}

.malta {
  background-position: 0 53.633218%;
}

.marshallislands {
  background-position: 0 53.979239%;
}

.martinique {
  background-position: 0 54.32526%;
}

.mauritania {
  background-position: 0 54.67128%;
}

.mauritius {
  background-position: 0 55.017301%;
}

.mayotte {
  background-position: 0 55.363322%;
}

.mexico {
  background-position: 0 55.709343%;
}

.micronesia {
  background-position: 0 56.055363%;
}

.moldova {
  background-position: 0 56.401384%;
}

.monaco {
  background-position: 0 56.747405%;
}

.mongolia {
  background-position: 0 57.093426%;
}

.montenegro {
  background-position: 0 57.439446%;
}

.montserrat {
  background-position: 0 57.785467%;
}

.morocco {
  background-position: 0 58.131488%;
}

.mozambique {
  background-position: 0 58.477509%;
}

.myanmar {
  background-position: 0 58.823529%;
}

.nato {
  background-position: 0 59.16955%;
}

.namibia {
  background-position: 0 59.515571%;
}

.nauru {
  background-position: 0 59.861592%;
}

.nepal {
  background-position: 0 60.207612%;
}

.netherlands {
  background-position: 0 60.553633%;
}

.netherlandsantilles {
  background-position: 0 60.899654%;
}

.newcaledonia {
  background-position: 0 61.245675%;
}

.newzealand {
  background-position: 0 61.591696%;
}

.nicaragua {
  background-position: 0 61.937716%;
}

.niger {
  background-position: 0 62.283737%;
}

.nigeria {
  background-position: 0 62.629758%;
}

.niue {
  background-position: 0 62.975779%;
}

.norfolk {
  background-position: 0 63.321799%;
}

.northamerica {
  background-position: 0 63.66782%;
}

.northkorea {
  background-position: 0 64.013841%;
}

.northerncyprus {
  background-position: 0 64.359862%;
}

.northernireland {
  background-position: 0 64.705882%;
}

.northernmariana {
  background-position: 0 65.051903%;
}

.norway {
  background-position: 0 65.397924%;
}

.oas {
  background-position: 0 65.743945%;
}

.opec {
  background-position: 0 66.089965%;
}

.oceania {
  background-position: 0 66.435986%;
}

.olimpics {
  background-position: 0 66.782007%;
}

.oman {
  background-position: 0 67.128028%;
}

.pakistan {
  background-position: 0 67.474048%;
}

.palau {
  background-position: 0 67.820069%;
}

.palestine {
  background-position: 0 68.16609%;
}

.panama {
  background-position: 0 68.512111%;
}

.papuanewguinea {
  background-position: 0 68.858131%;
}

.paraguay {
  background-position: 0 69.204152%;
}

.peru {
  background-position: 0 69.550173%;
}

.philippines {
  background-position: 0 69.896194%;
}

.pitcairn {
  background-position: 0 70.242215%;
}

.poland {
  background-position: 0 70.588235%;
}

.portugal {
  background-position: 0 70.934256%;
}

.puertorico {
  background-position: 0 71.280277%;
}

.qatar {
  background-position: 0 71.626298%;
}

.quebec {
  background-position: 0 71.972318%;
}

.redcross {
  background-position: 0 72.318339%;
}

.reunion {
  background-position: 0 72.66436%;
}

.romania {
  background-position: 0 73.010381%;
}

.russia {
  background-position: 0 73.356401%;
}

.rwanda {
  background-position: 0 73.702422%;
}

.saintbarthelemy {
  background-position: 0 74.048443%;
}

.sainthelena {
  background-position: 0 74.394464%;
}

.saintlucia {
  background-position: 0 74.740484%;
}

.saintmartin {
  background-position: 0 75.086505%;
}

.saintpierreandmiquelon {
  background-position: 0 75.432526%;
}

.samoa {
  background-position: 0 75.778547%;
}

.sanmarino {
  background-position: 0 76.124567%;
}

.saotomeandprincipe {
  background-position: 0 76.470588%;
}

.saudiarabia {
  background-position: 0 76.816609%;
}

.scotland {
  background-position: 0 77.16263%;
}

.senegal {
  background-position: 0 77.508651%;
}

.serbia {
  background-position: 0 77.854671%;
}

.seychelles {
  background-position: 0 78.200692%;
}

.sierraleone {
  background-position: 0 78.546713%;
}

.singapore {
  background-position: 0 78.892734%;
}

.sintmaarten {
  background-position: 0 79.238754%;
}

.slovakia {
  background-position: 0 79.584775%;
}

.slovenia {
  background-position: 0 79.930796%;
}

.solomonislands {
  background-position: 0 80.276817%;
}

.somalia {
  background-position: 0 80.622837%;
}

.somaliland {
  background-position: 0 80.968858%;
}

.southafrica {
  background-position: 0 81.314879%;
}

.southamerica {
  background-position: 0 81.6609%;
}

.southgeorgiaandsouthsandwich {
  background-position: 0 82.00692%;
}

.southkorea {
  background-position: 0 82.352941%;
}

.southsudan {
  background-position: 0 82.698962%;
}

.spain {
  background-position: 0 83.044983%;
}

.srilanka {
  background-position: 0 83.391003%;
}

.stkittsandnevis {
  background-position: 0 83.737024%;
}

.stvincentandthegrenadines {
  background-position: 0 84.083045%;
}

.sudan {
  background-position: 0 84.429066%;
}

.suriname {
  background-position: 0 84.775087%;
}

.svalbardandjanmayen {
  background-position: 0 85.121107%;
}

.swaziland {
  background-position: 0 85.467128%;
}

.sweden {
  background-position: 0 85.813149%;
}

.switzerland {
  background-position: 0 86.15917%;
}

.syria {
  background-position: 0 86.50519%;
}

.tahiti {
  background-position: 0 86.851211%;
}

.taiwan {
  background-position: 0 87.197232%;
}

.tajikistan {
  background-position: 0 87.543253%;
}

.tanzania {
  background-position: 0 87.889273%;
}

.thailand {
  background-position: 0 88.235294%;
}

.timorleste {
  background-position: 0 88.581315%;
}

.togo {
  background-position: 0 88.927336%;
}

.tokelau {
  background-position: 0 89.273356%;
}

.tonga {
  background-position: 0 89.619377%;
}

.trinidadtobago {
  background-position: 0 89.965398%;
}

.tristandacunha {
  background-position: 0 90.311419%;
}

.tunisia {
  background-position: 0 90.657439%;
}

.turkey {
  background-position: 0 91.00346%;
}

.turkmenistan {
  background-position: 0 91.349481%;
}

.turksandcaicosislands {
  background-position: 0 91.695502%;
}

.tuvalu {
  background-position: 0 92.041522%;
}

.usa {
  background-position: 0 92.387543%;
}

.uganda {
  background-position: 0 92.733564%;
}

.ukraine {
  background-position: 0 93.079585%;
}

.unitedarabemirates {
  background-position: 0 93.425606%;
}

.unitedkingdom {
  background-position: 0 93.771626%;
}

.uruguay {
  background-position: 0 94.117647%;
}

.uzbekistan {
  background-position: 0 94.463668%;
}

.vanutau {
  background-position: 0 94.809689%;
}

.vaticancity {
  background-position: 0 95.155709%;
}

.venezuela {
  background-position: 0 95.50173%;
}

.vietnam {
  background-position: 0 95.847751%;
}

.virginislandsbritish {
  background-position: 0 96.193772%;
}

.virginislandsus {
  background-position: 0 96.539792%;
}

.wales {
  background-position: 0 96.885813%;
}

.wallisandfutuna {
  background-position: 0 97.231834%;
}

.westernsahara {
  background-position: 0 97.577855%;
}

.world {
  background-position: 0 97.923875%;
}

.yemen {
  background-position: 0 98.269896%;
}

.zambia {
  background-position: 0 98.615917%;
}

.zimbabwe {
  background-position: 0 98.961938%;
}

.allupcoming {
  background-position: 0 99.307958%;
}

.america {
  background-position: 0 99.653979%;
}

.withvideo {
  background-position: 0 100%;
}