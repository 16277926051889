@import 'sass/settings';
@font-face {
  font-family: 'BetConstruct-Sport-Icons';
  src:  url('/icons/sports/fonts/BetConstruct-Sport-Icons.eot#{$version-control}');
  src:  url('/icons/sports/fonts/BetConstruct-Sport-Icons.eot#{$version-control}') format('embedded-opentype'),
        url('/icons/sports/fonts/BetConstruct-Sport-Icons.ttf#{$version-control}') format('truetype'),
        url('/icons/sports/fonts/BetConstruct-Sport-Icons.woff#{$version-control}') format('woff'),
        url('/icons/sports/fonts/BetConstruct-Sport-Icons.svg#{$version-control}#BetConstruct-Sport-Icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="bc-s-i-"], [class*=" bc-s-i-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'BetConstruct-Sport-Icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bc-s-i-Go:before {
  content: "\e923";
}
.bc-s-i-Rally:before {
  content: "\e924";
}
.bc-s-i-Kabaddi:before {
  content: "\e921";
}
.bc-s-i-RainbowSix:before {
  content: "\e922";
}
.bc-s-i-Petanque:before {
  content: "\e920";
}
.bc-s-i-ClashRoyale:before {
  content: "\e91f";
}
.bc-s-i-ETennis:before {
  content: "\e91e";
}
.bc-s-i-Bodybuilding-icon:before {
  content: "\e91d";
}
.bc-s-i-FIFA:before {
  content: "\e91c";
}
.bc-s-i-circuit:before {
  content: "\e91b";
}
.bc-s-i-PS4GAMING:before {
  content: "\e91a";
}
.bc-s-i-E-IceHockey:before {
  content: "\e919";
}
.bc-s-i-ThePenaltyKicks:before {
  content: "\e918";
}
.bc-s-i-BetOnBlackJack:before {
  content: "\e916";
}
.bc-s-i-LiveBetOnPoker:before {
  content: "\e917";
}
.bc-s-i-eSports_logo_sportbook:before {
  content: "\e915";
}
.bc-s-i-KingOfGlory:before {
  content: "\e911";
}
.bc-s-i-PUBG:before {
  content: "\e912";
}
.bc-s-i-RocketLeague:before {
  content: "\e913";
}
.bc-s-i-WarcraftIII:before {
  content: "\e914";
}
.bc-s-i-StreetFighterSymbol:before {
  content: "\e910";
}
.bc-s-i-E-sports:before {
  content: "\e90f";
}
.bc-s-i-CrossFire:before {
  content: "\e90e";
}
.bc-s-i-Halo:before {
  content: "\e90d";
}
.bc-s-i-blank:before {
  content: "\e670";
}
.bc-s-i-HON .path1:before {
  content: "\e906";
  color: rgb(0, 0, 0);
}
.bc-s-i-HON .path2:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.bc-s-i-HON .path3:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bc-s-i-HON .path4:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bc-s-i-HON .path5:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bc-s-i-HON .path6:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.bc-s-i-Vainglory:before {
  content: "\e90c";
}
.bc-s-i-VirtualCarRacing:before {
  content: "\e905";
}
.bc-s-i-CallOfDuty:before {
  content: "\e904";
}
.bc-s-i-CounterStrike:before {
  content: "\21";
}
.bc-s-i-LeagueOfLegends:before {
  content: "\22";
}
.bc-s-i-halo:before {
  content: "\23";
}
.bc-s-i-Dota2:before {
  content: "\24";
}
.bc-s-i-HeroesOfTheStorm:before {
  content: "\25";
}
.bc-s-i-Hearthstone:before {
  content: "\26";
}
.bc-s-i-MortalKombatXL:before {
  content: "\27";
}
.bc-s-i-WorldOfTanks:before {
  content: "\28";
}
.bc-s-i-WorldOfWarcraft:before {
  content: "\29";
}
.bc-s-i-Overwatch:before {
  content: "\2a";
}
.bc-s-i-Smite:before {
  content: "\2b";
}
.bc-s-i-smash:before {
  content: "\2c";
}
.bc-s-i-StarCraft2:before {
  content: "\2d";
}
.bc-s-i-outright:before {
  content: "\e903";
}
.bc-s-i-financials:before {
  content: "\e902";
}
.bc-s-i-EBasketball:before {
  content: "\e900";
}
.bc-s-i-CyberFootball:before {
  content: "\e901";
}
.bc-s-i-Bowls:before {
  content: "\e800";
}
.bc-s-i-Futsal:before {
  content: "\2e";
}
.bc-s-i-vbet-sport-icons_tablehockey:before {
  content: "\2f";
}
.bc-s-i-vbet-sport-icons_tabletennis:before {
  content: "\30";
}
.bc-s-i-vbet-sport-icons_taekwondo:before {
  content: "\31";
}
.bc-s-i-vbet-sport-icons_tankbiathlon:before {
  content: "\32";
}
.bc-s-i-TVShowsAndMovies:before {
  content: "\33";
}
.bc-s-i-vbet-sport-icons_tennis:before {
  content: "\34";
}
.bc-s-i-vbet-sport-icons_triathlon:before {
  content: "\35";
}
.bc-s-i-UFS:before {
  content: "\36";
}
.bc-s-i-vbet-sport-icons_ultimatefighting:before {
  content: "\37";
}
.bc-s-i-VirtualHorseRacing:before {
  content: "\38";
}
.bc-s-i-HorseRacing:before {
  content: "\39";
}
.bc-s-i-vbet-sport-icons_virtualtennis:before {
  content: "\3a";
}
.bc-s-i-vbet-sport-icons_volleyball:before {
  content: "\3b";
}
.bc-s-i-vbet-sport-icons_waterpolo:before {
  content: "\3c";
}
.bc-s-i-vbet-sport-icons_whatwherewhen:before {
  content: "\3d";
}
.bc-s-i-vbet-sport-icons_yachting:before {
  content: "\3e";
}
.bc-s-i-Soccer:before {
  content: "\e66f";
}
.bc-s-i-all:before {
  content: "\e600";
}
.bc-s-i-AlpineSkiing:before {
  content: "\e601";
}
.bc-s-i-AmericanFootball:before {
  content: "\e602";
}
.bc-s-i-Angling:before {
  content: "\e603";
}
.bc-s-i-Archery:before {
  content: "\e604";
}
.bc-s-i-AustralianFootball:before {
  content: "\e605";
}
.bc-s-i-Badminton:before {
  content: "\e606";
}
.bc-s-i-Balls:before {
  content: "\e607";
}
.bc-s-i-Baseball:before {
  content: "\e608";
}
.bc-s-i-Basketball:before {
  content: "\e609";
}
.bc-s-i-3x3:before {
  content: "\e671";
}
.bc-s-i-BeachHandball:before {
  content: "\e60a";
}
.bc-s-i-BeachFootball:before {
  content: "\e60b";
}
.bc-s-i-BeachVolleyball:before {
  content: "\e60c";
}
.bc-s-i-Biathlon:before {
  content: "\e60d";
}
.bc-s-i-Bobsleigh:before {
  content: "\e60e";
}
.bc-s-i-Boxing:before {
  content: "\e60f";
}
.bc-s-i-Bridge:before {
  content: "\e610";
}
.bc-s-i-Canoeing:before {
  content: "\e611";
}
.bc-s-i-Chess:before {
  content: "\e612";
}
.bc-s-i-Cricket:before {
  content: "\e613";
}
.bc-s-i-Curling:before {
  content: "\e614";
}
.bc-s-i-Cycling:before {
  content: "\e615";
}
.bc-s-i-Cyclo-Cross:before {
  content: "\e616";
}
.bc-s-i-Darts:before {
  content: "\e617";
}
.bc-s-i-Diving:before {
  content: "\e618";
}
.bc-s-i-ElectronicSports:before {
  content: "\e619";
}
.bc-s-i-Eurovision:before {
  content: "\e61a";
}
.bc-s-i-X-Sports:before {
  content: "\e61b";
}
.bc-s-i-Fencing:before {
  content: "\e61c";
}
.bc-s-i-FigureSkating:before {
  content: "\e61d";
}
.bc-s-i-Floorball:before {
  content: "\e61e";
}
.bc-s-i-Formula1:before {
  content: "\e61f";
}
.bc-s-i-FreestyleWrestling:before {
  content: "\e620";
}
.bc-s-i-GaelicFootball:before {
  content: "\e621";
}
.bc-s-i-Golf:before {
  content: "\e622";
}
.bc-s-i-GrassHockey:before {
  content: "\e623";
}
.bc-s-i-Greyhounds:before {
  content: "\e624";
}
.bc-s-i-Gymnastics:before {
  content: "\e625";
}
.bc-s-i-Handball:before {
  content: "\e626";
}
.bc-s-i-Weightlifting:before {
  content: "\e627";
}
.bc-s-i-BallHockey:before {
  content: "\e628";
}
.bc-s-i-Hockey:before {
  content: "\e629";
}
.bc-s-i-FutureHorseRacing:before {
  content: "\e62a";
}
.bc-s-i-Hurling:before {
  content: "\e62b";
}
.bc-s-i-IceHockey:before {
  content: "\e62c";
}
.bc-s-i-Judo:before {
  content: "\e62d";
}
.bc-s-i-Lacross:before {
  content: "\e62e";
}
.bc-s-i-Athletics:before {
  content: "\e62f";
}
.bc-s-i-Livemonitor:before {
  content: "\e630";
}
.bc-s-i-Lottery:before {
  content: "\e631";
}
.bc-s-i-Luge:before {
  content: "\e632";
}
.bc-s-i-Mafia:before {
  content: "\e633";
}
.bc-s-i-MiniSoccer:before {
  content: "\e634";
}
.bc-s-i-ModernPentathlon:before {
  content: "\e635";
}
.bc-s-i-Motorbikes:before {
  content: "\e636";
}
.bc-s-i-Motorsport:before {
  content: "\e637";
}
.bc-s-i-Nascar:before {
  content: "\e638";
}
.bc-s-i-Netball:before {
  content: "\e639";
}
.bc-s-i-NordicCombined:before {
  content: "\e63a";
}
.bc-s-i-WinterOlympics:before {
  content: "\e63b";
}
.bc-s-i-Oscar:before {
  content: "\e63c";
}
.bc-s-i-Padel:before {
  content: "\e63d";
}
.bc-s-i-Pesapallo:before {
  content: "\e63e";
}
.bc-s-i-PokerLive:before {
  content: "\e63f";
}
.bc-s-i-Poker:before {
  content: "\e640";
}
.bc-s-i-Politics:before {
  content: "\e641";
}
.bc-s-i-Pool:before {
  content: "\e642";
}
.bc-s-i-AutoRacing:before {
  content: "\e643";
}
.bc-s-i-RinkHockey:before {
  content: "\e644";
}
.bc-s-i-Rowing:before {
  content: "\e645";
}
.bc-s-i-RugbySevens:before {
  content: "\e646";
}
.bc-s-i-RugbyLeague:before {
  content: "\e647";
}
.bc-s-i-RugbyUnion:before {
  content: "\e648";
}
.bc-s-i-Schwingen:before {
  content: "\e649";
}
.bc-s-i-Shooting:before {
  content: "\e64a";
}
.bc-s-i-ShortTrackSpeedSkating:before {
  content: "\e64b";
}
.bc-s-i-Skeleton:before {
  content: "\e64c";
}
.bc-s-i-SkiJumping:before {
  content: "\e64d";
}
.bc-s-i-Skis:before {
  content: "\e64e";
}
.bc-s-i-Snooker:before {
  content: "\e64f";
}
.bc-s-i-Snowboard:before {
  content: "\e650";
}
.bc-s-i-VirtualFootballPro:before {
  content: "\e651";
}
.bc-s-i-SoccerWC2014:before {
  content: "\e652";
}
.bc-s-i-Softball:before {
  content: "\e653";
}
.bc-s-i-SpecialBets:before {
  content: "\e654";
}
.bc-s-i-Speedskating:before {
  content: "\e655";
}
.bc-s-i-Speedway:before {
  content: "\e656";
}
.bc-s-i-SportAndFun:before {
  content: "\e657";
}
.bc-s-i-SportFight:before {
  content: "\e658";
}
.bc-s-i-Squash:before {
  content: "\e659";
}
.bc-s-i-Sumo:before {
  content: "\e65a";
}
.bc-s-i-Surfing:before {
  content: "\e65b";
}
.bc-s-i-Swimming:before {
  content: "\e65c";
}
.bc-s-i-SynchronisedSwimming:before {
  content: "\e65d";
}
.bc-s-i-Tablehockey:before {
  content: "\e65e";
}
.bc-s-i-TableTennis:before {
  content: "\e65f";
}
.bc-s-i-Taekwondo:before {
  content: "\e660";
}
.bc-s-i-Tankbiathlon:before {
  content: "\e661";
}
.bc-s-i-Telecasts:before {
  content: "\e662";
}
.bc-s-i-Tennis:before {
  content: "\e663";
}
.bc-s-i-Triathlon:before {
  content: "\e664";
}
.bc-s-i-Mma:before {
  content: "\e665";
}
.bc-s-i-UltimateFighting:before {
  content: "\e666";
}
.bc-s-i-VirtualDogs:before {
  content: "\e667";
}
.bc-s-i-VirtualHorses:before {
  content: "\e668";
}
.bc-s-i-VirtualTennis:before {
  content: "\e669";
}
.bc-s-i-Volleyball:before {
  content: "\e66a";
}
.bc-s-i-WaterPolo:before {
  content: "\e66b";
}
.bc-s-i-WhatWhereWhen:before {
  content: "\e66c";
}
.bc-s-i-Wrestling:before {
  content: "\e66d";
}
.bc-s-i-GrecoRomanWrestling:before {
  content: "\e672";
}
.bc-s-i-Yachting:before {
  content: "\e66e";
}
