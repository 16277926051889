@import 'sass/settings';

//Content Start-----------------------------------------------------------------------------
@if ($isDesktop) {
  //-------------------------------------------------------
  //Layout Start-------------------------------------------
  //-------------------------------------------------------
  .layout-bc {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-areas: 'content-holder smart-panel-holder';
    grid-template-columns: 1fr auto;
    grid-gap: 0;
    &.sp-dynamic-content-opened {
      .lt-header-holder-bc, .lt-footer-holder-bc {
        filter: blur(10px);
        transition: filter $default-transition-duration;
      }
      .lt-dynamic-content-holder {
        .hm-aside-holder-bc, .hm-row-bc, .skinning-bc{
          transition: filter $default-transition-duration;
              filter: blur(10px);
        }
      }
    }
  }
  .lt-content-holder-bc {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-areas: 'header-holder' 'common-content-holder' 'footer-holder';
    grid-area: content-holder;
    grid-template-rows: auto 1fr auto;
    grid-gap: 0;
    -ms-grid-column: 1;
    &.home-page-fixed-sides {}
  }
  @keyframes blur-overlay {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }
  .lt-smart-panel-holder-bc {
    width: $desktop-smart-panel-width;
    height: 100%;
    position: relative;
    z-index: 30;
    grid-area: smart-panel-holder;
    -ms-grid-column: 2;
  }
  .lt-smart-panel-backface-layer-bc {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 3;
    background: rgba($hero-color, 0.2);
    background: var(--hero-color-rgba-20);
    [dir=ltr] & {
      left: 0;
      padding-right: $desktop-smart-panel-width + $desktop-dynamic-panel-width;
    }
    [dir=rtl] & {
      right: 0;
      padding-left: $desktop-smart-panel-width + $desktop-dynamic-panel-width;
    }
  }
  .lt-smart-panel-backface-bc {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  .lt-header-holder-bc {
    width: 100%;
    grid-area: header-holder;
    position: fixed;
    top: 0;
    z-index: 2;
    -ms-grid-row: 1;
    [dir=ltr] & {
      left: 0;
      padding-right: $desktop-smart-panel-width;
    }
    [dir=rtl] & {
      right: 0;
      padding-left: $desktop-smart-panel-width;
    }
    [dir=ltr] & {
      left: 0;
    }
    [dir=rtl] & {
      right: 0;
    }
  }
  .lt-common-content-holder-bc {
    display: grid;
    grid-area: common-content-holder;
    grid-template-areas: 'dynamic-content-holder betslip-holder';
    grid-template-columns: 1fr auto;
    grid-gap: 0;
    -ms-grid-row: 2;
    position: relative;
    z-index: 1;
    padding-top: $desktop-header-height;
  }
  .lt-dynamic-content-holder {
    grid-area: dynamic-content-holder;
    -ms-grid-column: 1;
  }
  .lt-betslip-holder {
    grid-area: betslip-holder;
    -ms-grid-column: 2;
  }
  .lt-footer-holder-bc {
    grid-area: footer-holder;
    -ms-grid-row: 3;
    position: relative;
    padding-top: 24px;
  }
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .layout-bc {
      height: initial;
      display: -ms-grid;
      -ms-grid-auto-flow: column;
      -ms-grid-template-columns: 1fr auto;
      -ms-grid-template-rows: 100%;
      -ms-grid-columns: 1fr auto;
      -ms-grid-rows: 100%;
    }
    .lt-content-holder-bc {
      display: -ms-grid;
      -ms-grid-auto-flow: row;
      -ms-grid-template-columns: auto;
      -ms-grid-template-rows: 100%;
      -ms-grid-columns: 100%;
      -ms-grid-rows: auto 1fr auto;
    }
    .lt-common-content-holder-bc {
      display: -ms-grid;
      -ms-grid-auto-flow: column;
      -ms-grid-template-columns: 1fr auto;
      -ms-grid-template-rows: 100%;
      -ms-grid-columns: 1fr auto;
      -ms-grid-rows: 100%;
      padding-top: $desktop-header-height;
    }
  }
  @media screen and (min-width: $desktop-breakpoint-1) {
    .lt-content-holder-bc {
      &.home-page-fixed-sides {}
    }
  }
  @media screen and (max-width: $desktop-breakpoint-1 - 1px) {
    .lt-content-holder-bc {
      &.home-page-fixed-sides {
      }
    }
  }
  //-------------------------------------------------------
  //Layout End---------------------------------------------
  //-------------------------------------------------------

  //-------------------------------------------------------
  //Entrance Form Start------------------------------------
  //-------------------------------------------------------
  .entrance-form-bc {
    //Popup Start------------------------------------------
    &.popup {
      &.login {
        margin-top: 30px;
      }
      &.registration {}
      .entrance-f-item-bc {
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .entrance-form-actions-holder-bc {
        &.login-ext-1 {
          margin-top: 10px;
        }
        &.reg-ext-1 {
          margin-top: 16px;
        }
      }
      /*.entrance-form-forgot-password-holder-bc {
        margin-top: 16px;
      }*/
    }
    //Popup End--------------------------------------------

    //Panel Start------------------------------------------
    &.panel {
      &.login {}
      &.registration {}
      .entrance-form-action-item-bc,
      .entrance-form-action-bc{
        width: 100%;
      }
      .entrance-f-item-bc {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .entrance-form-actions-holder-bc {
        &.login-ext-1 {
          margin-top: 8px;
        }
        &.reg-ext-1 {
          margin-top: 12px;
        }
      }
      /*.entrance-form-forgot-password-holder-bc {
        margin-top: 12px;
      }*/
    }
    //Panel End--------------------------------------------
  }
  .entrance-form-content-bc {
    display: table;
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    &.single-side {}
    &.double-sides {
      > .entrance-form-content-section-bc {
        [dir=ltr] & {
          border-right: 48px solid transparent;
        }
        [dir=rtl] & {
          border-left: 48px solid transparent;
        }
        &:last-child {
          border: none;
        }
      }
    }
  }
  .entrance-form-content-section-bc {
    display: table-cell;
    vertical-align: top;
  }
  .entrance-f-item-bc {
    &.reg-ext-1 {
      height: 62px;
      position: relative;
      > .input-control-holder-bc {
        &.recaptcha {
          position: absolute;
          top: 0;
          transform-origin: top center;

          [dir=ltr] & {
            left: 50%;
            transform: translateX(-50%) scale(0.8);
          }
          [dir=rtl] & {
            right: 50%;
            transform: translateX(50%) scale(0.8);
          }
        }
      }
    }
  }
  .entrance-form-actions-holder-bc {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    &.login-ext-1 {}
    &.reg-ext-1 {}
  }
  .entrance-form-action-item-bc {
    display: inline-block;
    vertical-align: top;
    &.left {
      [dir=ltr] & {
        float: left;
      }
      [dir=rtl] & {
        float: right;
      }
    }
    &.right {
      [dir=ltr] & {
        float: right;
      }
      [dir=rtl] & {
        float: left;
      }
    }
  }
  .entrance-form-action-bc {
    display: inline-block;
    vertical-align: top;
    min-width: 102px;
    height: 30px;
    padding: 0 8px;
    border: none;
    border-radius: $desktop-border-radius-size-1;
    outline: none;
    background-color: $hero-color;
    font-family: inherit;
    font-size: 16px;
    color: set-color($hero-color);
    text-transform: capitalize;
    transition-property: background-color, color;
    transition-duration: $default-transition-duration;
    cursor: pointer;
    &:hover {
      background-color: $hero-color-extended;
      color: set-color($hero-color-extended);
    }

  }
  /*.entrance-form-forgot-password-holder-bc {
    text-align: center;
    line-height: 0;
  }*/
  /*.entrance-f-i-forgot-password-bc {
    display: inline-block;
    vertical-align: top;
    border: none;
    outline: none;
    background-color: transparent;
    font-family: inherit;
    font-size: 12px;
    color: $default-text-palette-13;
    color: var(--text-palette-13);
    line-height: 14px;
    transition: color $default-transition-duration ease;
    cursor: pointer;
    &:hover {
      color: $hero-color;
      color: var(--hero-color);
    }
  }*/
  .entrance-form-error-message-holder-bc {
    margin-bottom: 6px;
  }
  .entrance-f-error-message-bc {
    display: block;
    font-weight: normal;
    font-size: 10px;
    color: $status-color-fail;
    color: var(--status-color-fail);
    line-height: 12px;
  }
  //-------------------------------------------------------
  //Entrance Form End--------------------------------------
  //-------------------------------------------------------
}

//Content End-------------------------------------------------------------------------------

//Content Start------------------------------------------------------------------------------
@if ($isMobile) {

}

//Content End--------------------------------------------------------------------------------
