  @function scheme-generator($prime-color, $reverse, $custom-color-config) {
//-------------------------------------------------//
    $hue: hue($prime-color);
    $saturation: saturation($prime-color);
    $lightness: lightness($prime-color);
    $alpha: alpha($prime-color);
//-------------------------------------------------//
    $palette-1: null;
    $palette-2: null;
    $palette-3: null;
    $palette-4: null;
    $palette-5: null;
    $palette-6: null;
    $palette-7: null;
    $palette-8: null;
    $palette-9: null;
    $palette-10: null;
    $palette-11: null;
    $palette-12: null;
    $palette-13: null;
    $palette-14: null;
    $palette-15: null;
    $palette-16: null;
    $palette-17: null;
    $palette-18: null;
    $palette-19: null;
    $palette-20: null;
//-------------------------------------------------//
    $palette-1: if(map-get($custom-color-config, color-1-lightness)  > 0, lighten($prime-color, abs(map-get($custom-color-config, color-1-lightness))), darken($prime-color, abs(map-get($custom-color-config, color-1-lightness))));
    $palette-2: if(map-get($custom-color-config, color-2-lightness)  > 0, lighten($prime-color, abs(map-get($custom-color-config, color-2-lightness))), darken($prime-color, abs(map-get($custom-color-config, color-2-lightness))));
    $palette-3: if(map-get($custom-color-config, color-3-lightness)  > 0, lighten($prime-color, abs(map-get($custom-color-config, color-3-lightness))), darken($prime-color, abs(map-get($custom-color-config, color-3-lightness))));
    $palette-4: if(map-get($custom-color-config, color-4-lightness)  > 0, lighten($prime-color, abs(map-get($custom-color-config, color-4-lightness))), darken($prime-color, abs(map-get($custom-color-config, color-4-lightness))));
    $palette-5: if(map-get($custom-color-config, color-5-lightness)  > 0, lighten($prime-color, abs(map-get($custom-color-config, color-5-lightness))), darken($prime-color, abs(map-get($custom-color-config, color-5-lightness))));
    $palette-6: if(map-get($custom-color-config, color-6-lightness)  > 0, lighten($prime-color, abs(map-get($custom-color-config, color-6-lightness))), darken($prime-color, abs(map-get($custom-color-config, color-6-lightness))));
    $palette-7: if(map-get($custom-color-config, color-7-lightness)  > 0, lighten($prime-color, abs(map-get($custom-color-config, color-7-lightness))), darken($prime-color, abs(map-get($custom-color-config, color-7-lightness))));
    $palette-8: if(map-get($custom-color-config, color-8-lightness)  > 0, lighten($prime-color, abs(map-get($custom-color-config, color-8-lightness))), darken($prime-color, abs(map-get($custom-color-config, color-8-lightness))));
    $palette-9: if(map-get($custom-color-config, color-9-lightness)  > 0, lighten($prime-color, abs(map-get($custom-color-config, color-9-lightness))), darken($prime-color, abs(map-get($custom-color-config, color-9-lightness))));
    $palette-10: if(map-get($custom-color-config, color-10-lightness) > 0, lighten($prime-color, abs(map-get($custom-color-config, color-10-lightness))), darken($prime-color, abs(map-get($custom-color-config, color-10-lightness))));
    $palette-11: if(map-get($custom-color-config, color-11-lightness) > 0, lighten($prime-color, abs(map-get($custom-color-config, color-11-lightness))), darken($prime-color, abs(map-get($custom-color-config, color-11-lightness))));
    $palette-12: if(map-get($custom-color-config, color-12-lightness) > 0, lighten($prime-color, abs(map-get($custom-color-config, color-12-lightness))), darken($prime-color, abs(map-get($custom-color-config, color-12-lightness))));
    $palette-13: if(map-get($custom-color-config, color-13-lightness) > 0, lighten($prime-color, abs(map-get($custom-color-config, color-13-lightness))), darken($prime-color, abs(map-get($custom-color-config, color-13-lightness))));
    $palette-14: if(map-get($custom-color-config, color-14-lightness) > 0, lighten($prime-color, abs(map-get($custom-color-config, color-14-lightness))), darken($prime-color, abs(map-get($custom-color-config, color-14-lightness))));
    $palette-15: if(map-get($custom-color-config, color-15-lightness) > 0, lighten($prime-color, abs(map-get($custom-color-config, color-15-lightness))), darken($prime-color, abs(map-get($custom-color-config, color-15-lightness))));
    $palette-16: if(map-get($custom-color-config, color-16-lightness) > 0, lighten($prime-color, abs(map-get($custom-color-config, color-16-lightness))), darken($prime-color, abs(map-get($custom-color-config, color-16-lightness))));
    $palette-17: if(map-get($custom-color-config, color-17-lightness) > 0, lighten($prime-color, abs(map-get($custom-color-config, color-17-lightness))), darken($prime-color, abs(map-get($custom-color-config, color-17-lightness))));
    $palette-18: if(map-get($custom-color-config, color-18-lightness) > 0, lighten($prime-color, abs(map-get($custom-color-config, color-18-lightness))), darken($prime-color, abs(map-get($custom-color-config, color-18-lightness))));
    $palette-19: if(map-get($custom-color-config, color-19-lightness) > 0, lighten($prime-color, abs(map-get($custom-color-config, color-19-lightness))), darken($prime-color, abs(map-get($custom-color-config, color-19-lightness))));
    $palette-20: if(map-get($custom-color-config, color-20-lightness) > 0, lighten($prime-color, abs(map-get($custom-color-config, color-20-lightness))), darken($prime-color, abs(map-get($custom-color-config, color-20-lightness))));
//-------------------------------------------------//
    $palette-1: if(map-get($custom-color-config, color-1-saturation)  > 0, saturate($palette-1, abs(map-get($custom-color-config, color-1-saturation))), desaturate($palette-1, abs(map-get($custom-color-config, color-1-saturation))));
    $palette-2: if(map-get($custom-color-config, color-2-saturation)  > 0, saturate($palette-2, abs(map-get($custom-color-config, color-2-saturation))), desaturate($palette-2, abs(map-get($custom-color-config, color-2-saturation))));
    $palette-3: if(map-get($custom-color-config, color-3-saturation)  > 0, saturate($palette-3, abs(map-get($custom-color-config, color-3-saturation))), desaturate($palette-3, abs(map-get($custom-color-config, color-3-saturation))));
    $palette-4: if(map-get($custom-color-config, color-4-saturation)  > 0, saturate($palette-4, abs(map-get($custom-color-config, color-4-saturation))), desaturate($palette-4, abs(map-get($custom-color-config, color-4-saturation))));
    $palette-5: if(map-get($custom-color-config, color-5-saturation)  > 0, saturate($palette-5, abs(map-get($custom-color-config, color-5-saturation))), desaturate($palette-5, abs(map-get($custom-color-config, color-5-saturation))));
    $palette-6: if(map-get($custom-color-config, color-6-saturation)  > 0, saturate($palette-6, abs(map-get($custom-color-config, color-6-saturation))), desaturate($palette-6, abs(map-get($custom-color-config, color-6-saturation))));
    $palette-7: if(map-get($custom-color-config, color-7-saturation)  > 0, saturate($palette-7, abs(map-get($custom-color-config, color-7-saturation))), desaturate($palette-7, abs(map-get($custom-color-config, color-7-saturation))));
    $palette-8: if(map-get($custom-color-config, color-8-saturation)  > 0, saturate($palette-8, abs(map-get($custom-color-config, color-8-saturation))), desaturate($palette-8, abs(map-get($custom-color-config, color-8-saturation))));
    $palette-9: if(map-get($custom-color-config, color-9-saturation)  > 0, saturate($palette-9, abs(map-get($custom-color-config, color-9-saturation))), desaturate($palette-9, abs(map-get($custom-color-config, color-9-saturation))));
    $palette-10: if(map-get($custom-color-config, color-10-saturation) > 0, saturate($palette-10, abs(map-get($custom-color-config, color-10-saturation))), desaturate($palette-10, abs(map-get($custom-color-config, color-10-saturation))));
    $palette-11: if(map-get($custom-color-config, color-11-saturation) > 0, saturate($palette-11, abs(map-get($custom-color-config, color-11-saturation))), desaturate($palette-11, abs(map-get($custom-color-config, color-11-saturation))));
    $palette-12: if(map-get($custom-color-config, color-12-saturation) > 0, saturate($palette-12, abs(map-get($custom-color-config, color-12-saturation))), desaturate($palette-12, abs(map-get($custom-color-config, color-12-saturation))));
    $palette-13: if(map-get($custom-color-config, color-13-saturation) > 0, saturate($palette-13, abs(map-get($custom-color-config, color-13-saturation))), desaturate($palette-13, abs(map-get($custom-color-config, color-13-saturation))));
    $palette-14: if(map-get($custom-color-config, color-14-saturation) > 0, saturate($palette-14, abs(map-get($custom-color-config, color-14-saturation))), desaturate($palette-14, abs(map-get($custom-color-config, color-14-saturation))));
    $palette-15: if(map-get($custom-color-config, color-15-saturation) > 0, saturate($palette-15, abs(map-get($custom-color-config, color-15-saturation))), desaturate($palette-15, abs(map-get($custom-color-config, color-15-saturation))));
    $palette-16: if(map-get($custom-color-config, color-16-saturation) > 0, saturate($palette-16, abs(map-get($custom-color-config, color-16-saturation))), desaturate($palette-16, abs(map-get($custom-color-config, color-16-saturation))));
    $palette-17: if(map-get($custom-color-config, color-17-saturation) > 0, saturate($palette-17, abs(map-get($custom-color-config, color-17-saturation))), desaturate($palette-17, abs(map-get($custom-color-config, color-17-saturation))));
    $palette-18: if(map-get($custom-color-config, color-18-saturation) > 0, saturate($palette-18, abs(map-get($custom-color-config, color-18-saturation))), desaturate($palette-18, abs(map-get($custom-color-config, color-18-saturation))));
    $palette-19: if(map-get($custom-color-config, color-19-saturation) > 0, saturate($palette-19, abs(map-get($custom-color-config, color-19-saturation))), desaturate($palette-19, abs(map-get($custom-color-config, color-19-saturation))));
    $palette-20: if(map-get($custom-color-config, color-20-saturation) > 0, saturate($palette-20, abs(map-get($custom-color-config, color-20-saturation))), desaturate($palette-20, abs(map-get($custom-color-config, color-20-saturation))));
//-------------------------------------------------//
    $palette-1: adjust-hue($palette-1, map-get($custom-color-config, color-1-hue));
    $palette-2: adjust-hue($palette-2, map-get($custom-color-config, color-2-hue));
    $palette-3: adjust-hue($palette-3, map-get($custom-color-config, color-3-hue));
    $palette-4: adjust-hue($palette-4, map-get($custom-color-config, color-4-hue));
    $palette-5: adjust-hue($palette-5, map-get($custom-color-config, color-5-hue));
    $palette-6: adjust-hue($palette-6, map-get($custom-color-config, color-6-hue));
    $palette-7: adjust-hue($palette-7, map-get($custom-color-config, color-7-hue));
    $palette-8: adjust-hue($palette-8, map-get($custom-color-config, color-8-hue));
    $palette-9: adjust-hue($palette-9, map-get($custom-color-config, color-9-hue));
    $palette-10: adjust-hue($palette-10, map-get($custom-color-config, color-10-hue));
    $palette-11: adjust-hue($palette-11, map-get($custom-color-config, color-11-hue));
    $palette-12: adjust-hue($palette-12, map-get($custom-color-config, color-12-hue));
    $palette-13: adjust-hue($palette-13, map-get($custom-color-config, color-13-hue));
    $palette-14: adjust-hue($palette-14, map-get($custom-color-config, color-15-hue));
    $palette-15: adjust-hue($palette-15, map-get($custom-color-config, color-15-hue));
    $palette-16: adjust-hue($palette-16, map-get($custom-color-config, color-16-hue));
    $palette-17: adjust-hue($palette-17, map-get($custom-color-config, color-17-hue));
    $palette-18: adjust-hue($palette-18, map-get($custom-color-config, color-18-hue));
    $palette-19: adjust-hue($palette-19, map-get($custom-color-config, color-19-hue));
    $palette-20: adjust-hue($palette-20, map-get($custom-color-config, color-20-hue));
//-------------------------------------------------//
    @if ($reverse) {
      $temp-color-1: $palette-1;
      $temp-color-2: $palette-2;
      $temp-color-3: $palette-3;
      $temp-color-4: $palette-4;
      $temp-color-5: $palette-5;
      $temp-color-6: $palette-6;
      $temp-color-7: $palette-7;
      $temp-color-8: $palette-8;
      $temp-color-9: $palette-9;
      $temp-color-10: $palette-10;
      $temp-color-11: $palette-11;
      $temp-color-12: $palette-12;
      $temp-color-13: $palette-13;
      $temp-color-14: $palette-14;
      $temp-color-15: $palette-15;
      $temp-color-16: $palette-16;
      $temp-color-17: $palette-17;
      $temp-color-18: $palette-18;
      $temp-color-19: $palette-19;
      $temp-color-20: $palette-20;
//-------------------------------------------------//
      $palette-1: $temp-color-20;
      $palette-2: $temp-color-19;
      $palette-3: $temp-color-18;
      $palette-4: $temp-color-17;
      $palette-5: $temp-color-16;
      $palette-6: $temp-color-15;
      $palette-7: $temp-color-14;
      $palette-8: $temp-color-13;
      $palette-9: $temp-color-12;
      $palette-10: $temp-color-11;
      $palette-11: $temp-color-10;
      $palette-12: $temp-color-9;
      $palette-13: $temp-color-8;
      $palette-14: $temp-color-7;
      $palette-15: $temp-color-6;
      $palette-16: $temp-color-5;
      $palette-17: $temp-color-4;
      $palette-18: $temp-color-3;
      $palette-19: $temp-color-2;
      $palette-20: $temp-color-1;
    }
//-------------------------------------------------//
    $scheme: (
            palette-1: $palette-1,
            palette-2: $palette-2,
            palette-3: $palette-3,
            palette-4: $palette-4,
            palette-5: $palette-5,
            palette-6: $palette-6,
            palette-7: $palette-7,
            palette-8: $palette-8,
            palette-9: $palette-9,
            palette-10: $palette-10,
            palette-11: $palette-11,
            palette-12: $palette-12,
            palette-13: $palette-13,
            palette-14: $palette-14,
            palette-15: $palette-15,
            palette-16: $palette-16,
            palette-17: $palette-17,
            palette-18: $palette-18,
            palette-19: $palette-19,
            palette-20: $palette-20
    );
    @return $scheme;
  }
//-------------------------------------------------//
  @mixin theme-generator($bg-scheme, $text-scheme, $root-class-name) {
    $bg-color-1: map-get($bg-scheme, palette-1);
    $bg-color-2: map-get($bg-scheme, palette-2);
    $bg-color-3: map-get($bg-scheme, palette-3);
    $bg-color-4: map-get($bg-scheme, palette-4);
    $bg-color-5: map-get($bg-scheme, palette-5);
    $bg-color-6: map-get($bg-scheme, palette-6);
    $bg-color-7: map-get($bg-scheme, palette-7);
    $bg-color-8: map-get($bg-scheme, palette-8);
    $bg-color-9: map-get($bg-scheme, palette-9);
    $bg-color-10: map-get($bg-scheme, palette-10);
    $bg-color-11: map-get($bg-scheme, palette-11);
    $bg-color-12: map-get($bg-scheme, palette-12);
    $bg-color-13: map-get($bg-scheme, palette-13);
    $bg-color-14: map-get($bg-scheme, palette-14);
    $bg-color-15: map-get($bg-scheme, palette-15);
    $bg-color-16: map-get($bg-scheme, palette-16);
    $bg-color-17: map-get($bg-scheme, palette-17);
    $bg-color-18: map-get($bg-scheme, palette-18);
    $bg-color-19: map-get($bg-scheme, palette-19);
    $bg-color-20: map-get($bg-scheme, palette-20);
//-------------------------------------------------//
    $text-color-1: map-get($text-scheme, palette-1);
    $text-color-2: map-get($text-scheme, palette-2);
    $text-color-3: map-get($text-scheme, palette-3);
    $text-color-4: map-get($text-scheme, palette-4);
    $text-color-5: map-get($text-scheme, palette-5);
    $text-color-6: map-get($text-scheme, palette-6);
    $text-color-7: map-get($text-scheme, palette-7);
    $text-color-8: map-get($text-scheme, palette-8);
    $text-color-9: map-get($text-scheme, palette-9);
    $text-color-10: map-get($text-scheme, palette-10);
    $text-color-11: map-get($text-scheme, palette-11);
    $text-color-12: map-get($text-scheme, palette-12);
    $text-color-13: map-get($text-scheme, palette-13);
    $text-color-14: map-get($text-scheme, palette-14);
    $text-color-15: map-get($text-scheme, palette-15);
    $text-color-16: map-get($text-scheme, palette-16);
    $text-color-17: map-get($text-scheme, palette-17);
    $text-color-18: map-get($text-scheme, palette-18);
    $text-color-19: map-get($text-scheme, palette-19);
    $text-color-20: map-get($text-scheme, palette-20);
//-------------------------------------------------//
    @supports (--css: variables) {
      .#{$root-class-name} {
        //-------------------------------------------------------
        //Base Colors Start--------------------------------------
        //-------------------------------------------------------
        --skin-palette-1: #{$bg-color-1};
        --skin-palette-2: #{$bg-color-2};
        --skin-palette-3: #{$bg-color-3};
        --skin-palette-4: #{$bg-color-4};
        --skin-palette-5: #{$bg-color-5};
        --skin-palette-6: #{$bg-color-6};
        --skin-palette-7: #{$bg-color-7};
        --skin-palette-8: #{$bg-color-8};
        --skin-palette-9: #{$bg-color-9};
        --skin-palette-10: #{$bg-color-10};
        --skin-palette-11: #{$bg-color-11};
        --skin-palette-12: #{$bg-color-12};
        --skin-palette-13: #{$bg-color-13};
        --skin-palette-14: #{$bg-color-14};
        --skin-palette-15: #{$bg-color-15};
        --skin-palette-16: #{$bg-color-16};
        --skin-palette-17: #{$bg-color-17};
        --skin-palette-18: #{$bg-color-18};
        --skin-palette-19: #{$bg-color-19};
        --skin-palette-20: #{$bg-color-20};
        //-------------------------------------------------------
        --text-palette-1: #{$text-color-1};
        --text-palette-2: #{$text-color-2};
        --text-palette-3: #{$text-color-3};
        --text-palette-4: #{$text-color-4};
        --text-palette-5: #{$text-color-5};
        --text-palette-6: #{$text-color-6};
        --text-palette-7: #{$text-color-7};
        --text-palette-8: #{$text-color-8};
        --text-palette-9: #{$text-color-9};
        --text-palette-10: #{$text-color-10};
        --text-palette-11: #{$text-color-11};
        --text-palette-12: #{$text-color-12};
        --text-palette-13: #{$text-color-13};
        --text-palette-14: #{$text-color-14};
        --text-palette-15: #{$text-color-15};
        --text-palette-16: #{$text-color-16};
        --text-palette-17: #{$text-color-17};
        --text-palette-18: #{$text-color-18};
        --text-palette-19: #{$text-color-19};
        --text-palette-20: #{$text-color-20};
        //-------------------------------------------------------
        //Base Colors End----------------------------------------
        //-------------------------------------------------------
//-------------------------------------------------//
        //-------------------------------------------------------
        //Common Colors Start------------------------------------
        //-------------------------------------------------------
        --html-bg: #{$bg-color-2};
        --scrollbar-track-bg: #{$bg-color-4};
        --scrollbar-thumb-bg: #{$bg-color-13};
        --smart-panel-bg-color: #{$bg-color-2};
//-------------------------------------------------//
        @if (lightness($bg-color-1) > 50) {
          --bg-color-white: #000000;
          --bg-color-black: #ffffff;
          --text-color-white: #000000;
          --text-color-black: #ffffff;
          //-------------------------------------------------------
          --bg-color-white-rgba-10: #{rgba(#000000, 0.1)};
          --bg-color-white-rgba-20: #{rgba(#000000, 0.2)};
          --bg-color-white-rgba-30: #{rgba(#000000, 0.3)};
          --bg-color-white-rgba-40: #{rgba(#000000, 0.4)};
          --bg-color-white-rgba-50: #{rgba(#000000, 0.5)};
          --bg-color-white-rgba-60: #{rgba(#000000, 0.6)};
          --bg-color-white-rgba-70: #{rgba(#000000, 0.7)};
          --bg-color-white-rgba-80: #{rgba(#000000, 0.8)};
          --bg-color-white-rgba-90: #{rgba(#000000, 0.9)};
          //-------------------------------------------------------
          --bg-color-black-rgba-10: #{rgba(#ffffff, 0.1)};
          --bg-color-black-rgba-20: #{rgba(#ffffff, 0.2)};
          --bg-color-black-rgba-30: #{rgba(#ffffff, 0.3)};
          --bg-color-black-rgba-40: #{rgba(#ffffff, 0.4)};
          --bg-color-black-rgba-50: #{rgba(#ffffff, 0.5)};
          --bg-color-black-rgba-60: #{rgba(#ffffff, 0.6)};
          --bg-color-black-rgba-70: #{rgba(#ffffff, 0.7)};
          --bg-color-black-rgba-80: #{rgba(#ffffff, 0.8)};
          --bg-color-black-rgba-90: #{rgba(#ffffff, 0.9)};
          //-------------------------------------------------------
        } @else {
          --bg-color-white: #ffffff;
          --bg-color-black: #000000;
          --text-color-white: #ffffff;
          --text-color-black: #000000;
          //-------------------------------------------------------
          --bg-color-white-rgba-10: #{rgba(#ffffff, 0.1)};
          --bg-color-white-rgba-20: #{rgba(#ffffff, 0.2)};
          --bg-color-white-rgba-30: #{rgba(#ffffff, 0.3)};
          --bg-color-white-rgba-40: #{rgba(#ffffff, 0.4)};
          --bg-color-white-rgba-50: #{rgba(#ffffff, 0.5)};
          --bg-color-white-rgba-60: #{rgba(#ffffff, 0.6)};
          --bg-color-white-rgba-70: #{rgba(#ffffff, 0.7)};
          --bg-color-white-rgba-80: #{rgba(#ffffff, 0.8)};
          --bg-color-white-rgba-90: #{rgba(#ffffff, 0.9)};
          //-------------------------------------------------------
          --bg-color-black-rgba-10: #{rgba(#000000, 0.1)};
          --bg-color-black-rgba-20: #{rgba(#000000, 0.2)};
          --bg-color-black-rgba-30: #{rgba(#000000, 0.3)};
          --bg-color-black-rgba-40: #{rgba(#000000, 0.4)};
          --bg-color-black-rgba-50: #{rgba(#000000, 0.5)};
          --bg-color-black-rgba-60: #{rgba(#000000, 0.6)};
          --bg-color-black-rgba-70: #{rgba(#000000, 0.7)};
          --bg-color-black-rgba-80: #{rgba(#000000, 0.8)};
          --bg-color-black-rgba-90: #{rgba(#000000, 0.9)};
          //-------------------------------------------------------
        }
        //---------------------------------------------------------
        --hero-color: #{$hero-color};
        --hero-color-extended: #{$hero-color-extended};
        --hero-color-extended-rgba-10: #{rgba($hero-color-extended, 0.1)};
        --hero-color-extended-rgba-20: #{rgba($hero-color-extended, 0.2)};
        --hero-color-extended-rgba-30: #{rgba($hero-color-extended, 0.3)};
        --hero-color-extended-rgba-40: #{rgba($hero-color-extended, 0.4)};
        --hero-color-extended-rgba-50: #{rgba($hero-color-extended, 0.5)};
        --hero-color-extended-rgba-60: #{rgba($hero-color-extended, 0.6)};
        --hero-color-extended-rgba-70: #{rgba($hero-color-extended, 0.7)};
        --hero-color-extended-rgba-80: #{rgba($hero-color-extended, 0.8)};
        --hero-color-extended-rgba-90: #{rgba($hero-color-extended, 0.9)};
        --hero-color-inner-text-color: #{$hero-color-inner-text-color};
        --hero-color-inner-text-color-extended: #{$hero-color-inner-text-color-extended};
        --hero-color-rgba-10: #{rgba($hero-color, 0.1)};
        --hero-color-rgba-20: #{rgba($hero-color, 0.2)};
        --hero-color-rgba-30: #{rgba($hero-color, 0.3)};
        --hero-color-rgba-40: #{rgba($hero-color, 0.4)};
        --hero-color-rgba-50: #{rgba($hero-color, 0.5)};
        --hero-color-rgba-60: #{rgba($hero-color, 0.6)};
        --hero-color-rgba-70: #{rgba($hero-color, 0.7)};
        --hero-color-rgba-80: #{rgba($hero-color, 0.8)};
        --hero-color-rgba-90: #{rgba($hero-color, 0.9)};
        //---------------------------------------------------------
        --brand-color-1: #{$brand-color-1};
        --brand-color-1-extended: #{$brand-color-1-extended};
        --brand-color-1-inner-text-color: #{$brand-color-1-inner-text-color};
        --brand-color-1-inner-text-color-extended: #{$brand-color-1-inner-text-color-extended};
        --brand-color-1-rgba-10: #{rgba($brand-color-1, 0.1)};
        --brand-color-1-rgba-20: #{rgba($brand-color-1, 0.2)};
        --brand-color-1-rgba-30: #{rgba($brand-color-1, 0.3)};
        --brand-color-1-rgba-40: #{rgba($brand-color-1, 0.4)};
        --brand-color-1-rgba-50: #{rgba($brand-color-1, 0.5)};
        --brand-color-1-rgba-60: #{rgba($brand-color-1, 0.6)};
        --brand-color-1-rgba-70: #{rgba($brand-color-1, 0.7)};
        --brand-color-1-rgba-80: #{rgba($brand-color-1, 0.8)};
        --brand-color-1-rgba-90: #{rgba($brand-color-1, 0.9)};
        //---------------------------------------------------------
        --brand-color-2: #{$brand-color-2};
        --brand-color-2-extended: #{$brand-color-2-extended};
        --brand-color-2-inner-text-color: #{$brand-color-2-inner-text-color};
        --brand-color-2-inner-text-color-extended: #{$brand-color-2-inner-text-color-extended};
        --brand-color-2-rgba-10: #{rgba($brand-color-2, 0.1)};
        --brand-color-2-rgba-20: #{rgba($brand-color-2, 0.2)};
        --brand-color-2-rgba-30: #{rgba($brand-color-2, 0.3)};
        --brand-color-2-rgba-40: #{rgba($brand-color-2, 0.4)};
        --brand-color-2-rgba-50: #{rgba($brand-color-2, 0.5)};
        --brand-color-2-rgba-60: #{rgba($brand-color-2, 0.6)};
        --brand-color-2-rgba-70: #{rgba($brand-color-2, 0.7)};
        --brand-color-2-rgba-80: #{rgba($brand-color-2, 0.8)};
        --brand-color-2-rgba-90: #{rgba($brand-color-2, 0.9)};
        //---------------------------------------------------------
        --status-color-fail: #{$status-color-fail};
        --status-color-fail-rgba-1: #{rgba($status-color-fail, 0.1)};
        --status-color-fail-rgba-2: #{rgba($status-color-fail, 0.2)};
        --status-color-fail-rgba-3: #{rgba($status-color-fail, 0.3)};
        --status-color-fail-rgba-4: #{rgba($status-color-fail, 0.4)};
        --status-color-fail-rgba-5: #{rgba($status-color-fail, 0.5)};
        --status-color-fail-rgba-6: #{rgba($status-color-fail, 0.6)};
        --status-color-fail-rgba-7: #{rgba($status-color-fail, 0.7)};
        --status-color-fail-rgba-8: #{rgba($status-color-fail, 0.8)};
        --status-color-fail-rgba-9: #{rgba($status-color-fail, 0.9)};
        //---------------------------------------------------------
        --status-color-success: #{$status-color-success};
        --status-color-success-rgba-1: #{rgba($status-color-success, 0.1)};
        --status-color-success-rgba-2: #{rgba($status-color-success, 0.2)};
        --status-color-success-rgba-3: #{rgba($status-color-success, 0.3)};
        --status-color-success-rgba-4: #{rgba($status-color-success, 0.4)};
        --status-color-success-rgba-5: #{rgba($status-color-success, 0.5)};
        --status-color-success-rgba-6: #{rgba($status-color-success, 0.6)};
        --status-color-success-rgba-7: #{rgba($status-color-success, 0.7)};
        --status-color-success-rgba-8: #{rgba($status-color-success, 0.8)};
        --status-color-success-rgba-9: #{rgba($status-color-success, 0.9)};
        //---------------------------------------------------------
        --notification-color: #{$notification-color};
        //---------------------------------------------------------
        --mobile-notification-color: #{$mobile-notification-color};
        //---------------------------------------------------------
        --prematch-color: #{$prematch-color};
        --live-color: #{$live-color};
        //---------------------------------------------------------
        --balance-color: #{$balance-color};
        //---------------------------------------------------------
        --market-bg-color: #{$bg-color-6};
        --market-bg-hover-active-color: #{$hero-color};
        --market-odds-color: #{$text-color-20};
        --market-odds-hover-active-color: #{$market-odds-hover-active-color};
        --market-odd-is-up-color: #{$market-odd-is-up-color};
        --market-odd-is-down-color: #{$market-odd-is-down-color};
        //---------------------------------------------------------
        --result-color: #{$result-color};
        //---------------------------------------------------------
        --is-live-color: #{$is-live-color};
        //---------------------------------------------------------
        --card-yellow: #{$card-yellow};
        --card-red: #{$card-red};
        //---------------------------------------------------------
        --favorite-color: #{$favorite-color};
        //---------------------------------------------------------
      }
      //-------------------------------------------------------
      //Common Colors Start------------------------------------
      //-------------------------------------------------------
    }
  }
