  @import "../dev/sass/constants";
  @import "color-scheme-core";
  @import "color-scheme-config-default";
  @import "mixins";
  @import "functions";
//-------------------------------------------------//
  //-------------------------------------------------------
  //Global Start-------------------------------------------
  //-------------------------------------------------------
  $isDesktop: false !default;
  $isMobile: false !default;
  $version-control: '?v=#{random(888)}';
  //-------------------------------------------------------
  $hasSportsBook: false !default;
  $hasCasino: false !default;
  //-------------------------------------------------------
  //Global End---------------------------------------------
  //-------------------------------------------------------
//-------------------------------------------------//
  //-------------------------------------------------------
  //Typography Start---------------------------------------
  //-------------------------------------------------------
  $font-family: Roboto, Arial, Helvetica, sans-serif;
  $golden-ratio: 1.6180334;
  //-------------------------------------------------------
  //Typography End-----------------------------------------
  //-------------------------------------------------------
//-------------------------------------------------//
  //-------------------------------------------------------
  //Common Start-------------------------------------------
  //-------------------------------------------------------
  $default-transition-duration: 0.24s !default;
  //-------------------------------------------------------
  //Common End---------------------------------------------
  //-------------------------------------------------------
//-------------------------------------------------//
  //-------------------------------------------------------
  //Layout Start-------------------------------------------
  //-------------------------------------------------------
  $scrollbar-width: 2px;
  $scrollbar-thumb-border-radius: 2px;
  //-------------------------------------------------------
  $desktop-header-stage-1-height: 32px !default;
  $desktop-header-stage-2-height: 52px !default;
  $desktop-header-stage-3-height: 24px !default;
  $desktop-header-height: $desktop-header-stage-1-height + $desktop-header-stage-2-height + $desktop-header-stage-3-height;
  //-------------------------------------------------------
  $desktop-border-radius-size-1: 2px !default;
  $desktop-border-radius-size-2: 4px !default;
  $desktop-border-radius-size-3: 8px !default;
  $desktop-border-radius-size-4: 1px !default;
  //-------------------------------------------------------
  $desktop-smart-panel-width: 48px !default;
  $desktop-smart-panel-live-support-height: 50px;
  //-------------------------------------------------------
  $desktop-smart-panel-button-holder-vertical: 50px !default;
  $desktop-smart-panel-button-padding-vertical: 4px !default;
  $desktop-smart-panel-button-width-height: 42px;
  $desktop-smart-panel-buttons-border-radius: $desktop-border-radius-size-1 !default;
  //-------------------------------------------------------
  $desktop-home-page-aside-width: 272px;
  $desktop-home-page-content-width: 1328px !default;
  $desktop-home-page-gutter: 8px !default;
  $desktop-home-page-content-border-radius: 8px !default;
  //-------------------------------------------------------
  $desktop-dynamic-panel-width: 340px !default;
  //-------------------------------------------------------
  $desktop-entrance-popup-banner-width: 254px !default;
  $desktop-entrance-popup-sign-in-width: 688px !default;
  $desktop-entrance-popup-registration-width: 688px !default;
  $desktop-entrance-popup-height: 578px !default;
  //-------------------------------------------------------
  $mobile-header-navigation-height: 42px;
  $mobile-header-main-section-height: 46px;
  $mobile-smart-panel-height: 42px;
  //-------------------------------------------------------
  $mobile-betslip-compact-height: 42px !default;
  //Layout End---------------------------------------------
  //-------------------------------------------------------
//-------------------------------------------------//
  //-------------------------------------------------------
  //Breakpoints Start--------------------------------------
  //-------------------------------------------------------
  $desktop-breakpoint-1: 1359px;
  //-------------------------------------------------------
  //Breakpoints End----------------------------------------
  //-------------------------------------------------------
//-------------------------------------------------//
  //-------------------------------------------------------
  //Skin Generation Start----------------------------------
  //-------------------------------------------------------
  $default-skin-color: #020203 !default;
  $default-bg-scheme: scheme-generator($default-skin-color, false, $default-bg-scheme-config) !default;
  $default-skin-palette-1: map-get($default-bg-scheme, palette-1) !default; //#020203
  $default-skin-palette-2: map-get($default-bg-scheme, palette-2) !default; //#0C0C12
  $default-skin-palette-3: map-get($default-bg-scheme, palette-3) !default; //#161622
  $default-skin-palette-4: map-get($default-bg-scheme, palette-4) !default; //#212131
  $default-skin-palette-5: map-get($default-bg-scheme, palette-5) !default; //#2B2B40
  $default-skin-palette-6: map-get($default-bg-scheme, palette-6) !default; //#353550
  $default-skin-palette-7: map-get($default-bg-scheme, palette-7) !default; //#3F3F5F
  $default-skin-palette-8: map-get($default-bg-scheme, palette-8) !default; //#49496E
  $default-skin-palette-9: map-get($default-bg-scheme, palette-9) !default; //#54547D
  $default-skin-palette-10: map-get($default-bg-scheme, palette-10) !default; //#616189
  $default-skin-palette-11: map-get($default-bg-scheme, palette-11) !default; //#6E6E96
  $default-skin-palette-12: map-get($default-bg-scheme, palette-12) !default; //#7D7DA1
  $default-skin-palette-13: map-get($default-bg-scheme, palette-13) !default; //#8C8CAB
  $default-skin-palette-14: map-get($default-bg-scheme, palette-14) !default; //#9A9AB6
  $default-skin-palette-15: map-get($default-bg-scheme, palette-15) !default; //#A9A9C1
  $default-skin-palette-16: map-get($default-bg-scheme, palette-16) !default; //#B6B6CE
  $default-skin-palette-17: map-get($default-bg-scheme, palette-17) !default; //#C5C5D8
  $default-skin-palette-18: map-get($default-bg-scheme, palette-18) !default; //#D4D4E2
  $default-skin-palette-19: map-get($default-bg-scheme, palette-19) !default; //#E3E3ED
  $default-skin-palette-20: map-get($default-bg-scheme, palette-20) !default; //#F3F3F7
  //-------------------------------------------------------
  //Skin Generation End------------------------------------
  //-------------------------------------------------------
//-------------------------------------------------//
  //-------------------------------------------------------
  //Text Generation Start----------------------------------
  //-------------------------------------------------------
  $default-text-color: #000 !default;
  $default-text-scheme: scheme-generator($default-text-color, false, $default-text-scheme-config) !default;
  $default-text-palette-1: map-get($default-text-scheme, palette-1) !default; //#000000
  $default-text-palette-2: map-get($default-text-scheme, palette-2) !default; //#0D0D0D
  $default-text-palette-3: map-get($default-text-scheme, palette-3) !default; //#1A1A1A
  $default-text-palette-4: map-get($default-text-scheme, palette-4) !default; //#262626
  $default-text-palette-5: map-get($default-text-scheme, palette-5) !default; //#333333
  $default-text-palette-6: map-get($default-text-scheme, palette-6) !default; //#404040
  $default-text-palette-7: map-get($default-text-scheme, palette-7) !default; //#4D4D4D
  $default-text-palette-8: map-get($default-text-scheme, palette-8) !default; //#595959
  $default-text-palette-9: map-get($default-text-scheme, palette-9) !default; //#666666
  $default-text-palette-10: map-get($default-text-scheme, palette-10) !default; //#737373
  $default-text-palette-11: map-get($default-text-scheme, palette-11) !default; //#808080
  $default-text-palette-12: map-get($default-text-scheme, palette-12) !default; //#8C8C8C
  $default-text-palette-13: map-get($default-text-scheme, palette-13) !default; //#999999
  $default-text-palette-14: map-get($default-text-scheme, palette-14) !default; //#A6A6A6
  $default-text-palette-15: map-get($default-text-scheme, palette-15) !default; //#B3B3B3
  $default-text-palette-16: map-get($default-text-scheme, palette-16) !default; //#BFBFBF
  $default-text-palette-17: map-get($default-text-scheme, palette-17) !default; //#CCCCCC
  $default-text-palette-18: map-get($default-text-scheme, palette-18) !default; //#D9D9D9
  $default-text-palette-19: map-get($default-text-scheme, palette-19) !default; //#E6E6E6
  $default-text-palette-20: map-get($default-text-scheme, palette-20) !default; //#F2F2F2
  //-------------------------------------------------------
  //Text Generation End------------------------------------
  //-------------------------------------------------------
//-------------------------------------------------//
  //-------------------------------------------------------
  //Common Colors Start------------------------------------
  //-------------------------------------------------------
  $html-bg: $default-skin-palette-2 !default;
  $scrollbar-track-bg: $default-skin-palette-4 !default;
  $scrollbar-thumb-bg: $default-skin-palette-13 !default;
  $smart-panel-bg-color: $default-skin-palette-2 !default;
  //-------------------------------------------------------
  //Common Colors Start------------------------------------
  //-------------------------------------------------------
//-------------------------------------------------//
  //-------------------------------------------------------
  //Accent Colors Start------------------------------------
  //-------------------------------------------------------
  @if (lightness($default-skin-color) > 50) {
    $bg-color-white: #000000 !global !default;
    $bg-color-black: #ffffff !global !default;
    //-----------------------------------------------------
    $text-color-white: #000000 !global !default;
    $text-color-black: #ffffff !global !default;
  } @else {
    $bg-color-white: #ffffff !global !default;
    $bg-color-black: #000000 !global !default;
    //-----------------------------------------------------
    $text-color-white: #ffffff !global !default;
    $text-color-black: #000000 !global !default;
  }
//-------------------------------------------------//
  //-------------------------------------------------------
  $bg-color-white-rgba-10: rgba($bg-color-white, 0.1);
  $bg-color-white-rgba-20: rgba($bg-color-white, 0.2);
  $bg-color-white-rgba-30: rgba($bg-color-white, 0.3);
  $bg-color-white-rgba-40: rgba($bg-color-white, 0.4);
  $bg-color-white-rgba-50: rgba($bg-color-white, 0.5);
  $bg-color-white-rgba-60: rgba($bg-color-white, 0.6);
  $bg-color-white-rgba-70: rgba($bg-color-white, 0.7);
  $bg-color-white-rgba-80: rgba($bg-color-white, 0.8);
  $bg-color-white-rgba-90: rgba($bg-color-white, 0.9);
  //------------------------------------------------------
  $bg-color-black-rgba-10: rgba($bg-color-black, 0.1);
  $bg-color-black-rgba-20: rgba($bg-color-black, 0.2);
  $bg-color-black-rgba-30: rgba($bg-color-black, 0.3);
  $bg-color-black-rgba-40: rgba($bg-color-black, 0.4);
  $bg-color-black-rgba-50: rgba($bg-color-black, 0.5);
  $bg-color-black-rgba-60: rgba($bg-color-black, 0.6);
  $bg-color-black-rgba-70: rgba($bg-color-black, 0.7);
  $bg-color-black-rgba-80: rgba($bg-color-black, 0.8);
  $bg-color-black-rgba-90: rgba($bg-color-black, 0.9);
  //------------------------------------------------------
  $hero-color-extended: darken($hero-color, 10%) !default;
  $hero-color-extended-rgba-10: #{rgba($hero-color-extended, 0.1)};
  $hero-color-extended-rgba-20: #{rgba($hero-color-extended, 0.2)};
  $hero-color-extended-rgba-30: #{rgba($hero-color-extended, 0.3)};
  $hero-color-extended-rgba-40: #{rgba($hero-color-extended, 0.4)};
  $hero-color-extended-rgba-50: #{rgba($hero-color-extended, 0.5)};
  $hero-color-extended-rgba-60: #{rgba($hero-color-extended, 0.6)};
  $hero-color-extended-rgba-70: #{rgba($hero-color-extended, 0.7)};
  $hero-color-extended-rgba-80: #{rgba($hero-color-extended, 0.8)};
  $hero-color-extended-rgba-90: #{rgba($hero-color-extended, 0.9)};
  $hero-color-inner-text-color: #ffffff !default;
  $hero-color-inner-text-color-extended: $hero-color-inner-text-color !default;
  $hero-color-rgba-10: #{rgba($hero-color, 0.1)};
  $hero-color-rgba-20: #{rgba($hero-color, 0.2)};
  $hero-color-rgba-30: #{rgba($hero-color, 0.3)};
  $hero-color-rgba-40: #{rgba($hero-color, 0.4)};
  $hero-color-rgba-50: #{rgba($hero-color, 0.5)};
  $hero-color-rgba-60: #{rgba($hero-color, 0.6)};
  $hero-color-rgba-70: #{rgba($hero-color, 0.7)};
  $hero-color-rgba-80: #{rgba($hero-color, 0.8)};
  $hero-color-rgba-90: #{rgba($hero-color, 0.9)};
  //------------------------------------------------------
  $brand-color-1-extended: darken($brand-color-1, 10%) !default;
  $brand-color-1-inner-text-color: #ffffff !default;
  $brand-color-1-inner-text-color-extended: $brand-color-1-inner-text-color !default;
  $brand-color-1-rgba-10: #{rgba($brand-color-1, 0.1)};
  $brand-color-1-rgba-20: #{rgba($brand-color-1, 0.2)};
  $brand-color-1-rgba-30: #{rgba($brand-color-1, 0.3)};
  $brand-color-1-rgba-40: #{rgba($brand-color-1, 0.4)};
  $brand-color-1-rgba-50: #{rgba($brand-color-1, 0.5)};
  $brand-color-1-rgba-60: #{rgba($brand-color-1, 0.6)};
  $brand-color-1-rgba-70: #{rgba($brand-color-1, 0.7)};
  $brand-color-1-rgba-80: #{rgba($brand-color-1, 0.8)};
  $brand-color-1-rgba-90: #{rgba($brand-color-1, 0.9)};
  //------------------------------------------------------
  $brand-color-2-extended: darken($brand-color-2, 10%) !default;
  $brand-color-2-inner-text-color: #ffffff !default;
  $brand-color-2-inner-text-color-extended: $brand-color-2-inner-text-color !default;
  $brand-color-2-rgba-10: #{rgba($brand-color-2, 0.1)};
  $brand-color-2-rgba-20: #{rgba($brand-color-2, 0.2)};
  $brand-color-2-rgba-30: #{rgba($brand-color-2, 0.3)};
  $brand-color-2-rgba-40: #{rgba($brand-color-2, 0.4)};
  $brand-color-2-rgba-50: #{rgba($brand-color-2, 0.5)};
  $brand-color-2-rgba-60: #{rgba($brand-color-2, 0.6)};
  $brand-color-2-rgba-70: #{rgba($brand-color-2, 0.7)};
  $brand-color-2-rgba-80: #{rgba($brand-color-2, 0.8)};
  $brand-color-2-rgba-90: #{rgba($brand-color-2, 0.9)};
  //-------------------------------------------------------
  $status-color-fail-rgba-1: #{rgba($status-color-fail, 0.1)};
  $status-color-fail-rgba-2: #{rgba($status-color-fail, 0.2)};
  $status-color-fail-rgba-3: #{rgba($status-color-fail, 0.3)};
  $status-color-fail-rgba-4: #{rgba($status-color-fail, 0.4)};
  $status-color-fail-rgba-5: #{rgba($status-color-fail, 0.5)};
  $status-color-fail-rgba-6: #{rgba($status-color-fail, 0.6)};
  $status-color-fail-rgba-7: #{rgba($status-color-fail, 0.7)};
  $status-color-fail-rgba-8: #{rgba($status-color-fail, 0.8)};
  $status-color-fail-rgba-9: #{rgba($status-color-fail, 0.9)};
  //-------------------------------------------------------
  $status-color-success-rgba-1: #{rgba($status-color-success, 0.1)};
  $status-color-success-rgba-2: #{rgba($status-color-success, 0.2)};
  $status-color-success-rgba-3: #{rgba($status-color-success, 0.3)};
  $status-color-success-rgba-4: #{rgba($status-color-success, 0.4)};
  $status-color-success-rgba-5: #{rgba($status-color-success, 0.5)};
  $status-color-success-rgba-6: #{rgba($status-color-success, 0.6)};
  $status-color-success-rgba-7: #{rgba($status-color-success, 0.7)};
  $status-color-success-rgba-8: #{rgba($status-color-success, 0.8)};
  $status-color-success-rgba-9: #{rgba($status-color-success, 0.9)};
  //-------------------------------------------------------
  $notification-color: #F5A623 !default;
  //-------------------------------------------------------
  $mobile-notification-color: #079264 !default;
  $mobile-accept-notification-color: #079264 !default;
  $mobile-warning-notification-color: #F5A623 !default;
  $mobile-error-notification-color: #B32336 !default;
  //-------------------------------------------------------
  $prematch-color: #F5A623 !default;
  $live-color: #DA4F41 !default;
  //-------------------------------------------------------
  $balance-color: #d29423 !default;
  //-------------------------------------------------------
  $market-bg-color: $default-skin-palette-6 !default;
  $market-bg-hover-active-color: $hero-color !default;
  $market-odds-color: $default-text-palette-20 !default;
  $market-odds-hover-active-color: #ffffff !default;
  $market-odd-is-up-color: #079264 !default;
  $market-odd-is-down-color: #B32336 !default;
  //-------------------------------------------------------
  $result-color: #F5A623 !default;
  //-------------------------------------------------------
  $card-yellow: #F7C23B !default;
  $card-red: #D0021B !default;
  //-------------------------------------------------------
  $favorite-color: #F5A623 !default;
  //-------------------------------------------------------
  $is-live-color: #008d47 !default;
  //-------------------------------------------------------
//-------------------------------------------------//
  //-------------------------------------------------------
  //Accent Colors End--------------------------------------
  //-------------------------------------------------------
