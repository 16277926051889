  @function getUnderlyingColor($color) {
    $lightness: lightness($color);
    @if ($lightness > 50) {
      @return #000000;
    } @else {
      @return #ffffff;
    }
  }
  @function set-color($color, $color-dark: #000000, $color-light: #ffffff) {
      @if (lightness($color) > 50) {
          @return $color-dark;
      } @else {
          @return $color-light;
      }
  }
