@import 'sass/settings';

@font-face {
  font-family: 'Open Sans';
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
  unicode-range: U+0530-058F;
  src: local('mardoto-regular'),
  url('/fonts/armenian/mardoto-regular-webfont.woff2#{$version-control}') format('woff2'),
  url('/fonts/armenian/mardoto-regular-webfont.woff#{$version-control}') format('woff'),
  url('/fonts/armenian/mardoto-regular-webfont.ttf#{$version-control}') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
  unicode-range: U+0530-058F;
  src: local('mardoto-bold'),
  url('/fonts/armenian/mardoto-bold-webfont.woff2#{$version-control}') format('woff2'),
  url('/fonts/armenian/mardoto-bold-webfont.woff#{$version-control}') format('woff'),
  url('/fonts/armenian/mardoto-bold-webfont.ttf#{$version-control}') format('truetype');
}
