@import 'sass/settings';

@font-face {
  font-family: Roboto;
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
  unicode-range: U+0600-06FF;
  src: local('IRAN_SemiBold'),
  url('/fonts/farsi/IRAN_SemiBold.woff2#{$version-control}') format('woff2'),
  url('/fonts/farsi/IRAN_SemiBold.woff#{$version-control}') format('woff'),
  url('/fonts/farsi/IRAN_SemiBold.ttf#{$version-control}') format('truetype'),
  url('/fonts/farsi/IRAN_SemiBold.eot#{$version-control}');
}

@font-face {
  font-family: Roboto;
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
  unicode-range: U+0600-06FF;
  src: local('IRAN_SemiBold'),
  url('/fonts/farsi/IRAN_SemiBold.woff2#{$version-control}') format('woff2'),
  url('/fonts/farsi/IRAN_SemiBold.woff#{$version-control}') format('woff'),
  url('/fonts/farsi/IRAN_SemiBold.ttf#{$version-control}') format('truetype'),
  url('/fonts/farsi/IRAN_SemiBold.eot#{$version-control}');
}
