@import 'sass/settings';

//Desktop Start-----------------------------------------------------------------------------
@if ($isDesktop) {

  .help-popup {
    display: inline-flex;
    width: 100%;
    max-width: 980px;
    height: 520px;
    padding: 20px 20px 20px 0;
    border-top: 30px solid $default-skin-palette-3;
    border-top: 30px solid var(--skin-palette-3);
    box-shadow: 0 0 0 20px $default-skin-palette-3 inset;
    box-shadow: 0 0 0 20px var(--skin-palette-3) inset;
    background-color: $default-skin-palette-4;
    background-color: var(--skin-palette-4);
    margin: 0 auto;

    .navigation {
      width: 240px;
      padding: 0 20px 20px;
      font-size: 11px;
      line-height: 16px;
      background-color: $default-skin-palette-3;
      background-color: var(--skin-palette-3);
      ul {
        margin-bottom: 10px;
        > li{
          font-size: 12px;
          line-height: 14px;
          margin-top: 8px;
          > span{
            transition: color $default-transition-duration;
            cursor: pointer;
            color: $default-text-palette-11;
            color: var(--text-palette-11);

            &:hover{
              color: $default-text-palette-13;
              color: var(--text-palette-13);
            }
          }
          > a{
            transition: color $default-transition-duration;
            text-decoration: none;
            color: $default-text-palette-11;
            color: var(--text-palette-11);

            &:hover{
              color: $default-text-palette-13;
              color: var(--text-palette-13);
            }
          }
        }
      }
    }

    .content {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 10px 20px 20px;
      flex: 1;
      color: $default-text-palette-11;
      color: var(--text-palette-11);
      text-align: justify;
      font-size: 13px;
      line-height: 18px;
      box-shadow: 0 0 0 1px $default-skin-palette-5;
      box-shadow: 0 0 0 1px var(--skin-palette-5);
      a{
        color: $default-text-palette-14;
        color: var(--text-palette-14);
        text-decoration: underline;
        transition: color $default-transition-duration;
        &:hover{
          color: $default-text-palette-12;
          color: var(--text-palette-12);
        }
      }
      p{
        margin: 0 0 20px;
      }
      strong{
        font-size: 16px;
        color: $default-text-palette-20;
        color: var(--text-palette-20);
        u{
          color: $default-text-palette-20;
          color: var(--text-palette-20);
          margin: 10px 0;
          display: block;
        }
        em{
          font-size: 14px;
        }
      }
    }
  }
}

//Desktop End-------------------------------------------------------------------------------

//Mobile Start------------------------------------------------------------------------------
@if ($isMobile) {
  .help-popup{
    width: 100%;
    padding-top: 20px;
    .sp-dy-section-head-bc{
      display: none;
    }
  }
.navigation{
  position: fixed;
  top: 0;
  width: calc(100% - 50px);
  height: 50px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  font-size: 14px;
  padding: 0 10px;
  line-height: 50px;
  background-color: $default-skin-palette-5;
  background-color: var(--skin-palette-5);
  color: $default-text-palette-20;
  color: var(--text-palette-20);
  box-shadow: 0 0 5px $bg-color-black-rgba-70;
  &:before{
    width: 50px;
    height: 50px;
    content: "";
    display: block;
    position: fixed;
    right: 0;
    top: 0;
    background-color: $default-skin-palette-5;
    background-color: var(--skin-palette-5);
    box-shadow: 0 0 5px $bg-color-black-rgba-70;
  }
    > li{
      display: inline-block;
      vertical-align: top;
      width: auto;
      > ul{
        display: inline-block;
        vertical-align: top;
        > li{
          display: inline-block;
          vertical-align: top;
          width: auto;
        }
      }
    }
  }
  .tr-nav-link-bc{
    text-decoration: none;
    padding: 0 10px;

  }
  .tr-nav-link-holder-bc{
    > a{
      text-decoration: none;
      padding: 0 10px;
      color: $default-text-palette-14;
      color: var(--text-palette-14);
    }
  }


  .content {
    height: 100%;
    padding: 50px 20px 20px;
    color: $default-text-palette-11;
    color: var(--text-palette-11);
    font-size: 13px;
    line-height: 18px;
    box-shadow: 0 0 0 1px $default-skin-palette-5;
    box-shadow: 0 0 0 1px var(--skin-palette-5);
    a{
      color: $default-text-palette-14;
      color: var(--text-palette-14);
      text-decoration: underline;
      transition: color $default-transition-duration;
      &:hover{
        color: $default-text-palette-12;
        color: var(--text-palette-12);
      }
    }
    p{
      margin: 0 0 20px;
    }
    strong{
      font-size: 16px;
      color: $default-text-palette-20;
      color: var(--text-palette-20);
      font-style: normal;
      u{
        color: $default-text-palette-20;
        color: var(--text-palette-20);
        margin: 10px 0;
        display: block;
      }
      em{
        font-size: 14px;
        font-style: normal;
      }
    }
  }

}
//Mobile End------------------------------------------------------------------------------

