@import 'sass/settings';

@font-face {
  font-family: 'BetConstruct-Icons';
  src: url('/icons/general/fonts/BetConstruct-Icons.eot#{$version-control}');
  src: url('/icons/general/fonts/BetConstruct-Icons.eot#{$version-control}') format('embedded-opentype'),
       url('/icons/general/fonts/BetConstruct-Icons.ttf#{$version-control}') format('truetype'),
       url('/icons/general/fonts/BetConstruct-Icons.woff#{$version-control}') format('woff'),
       url('/icons/general/fonts/BetConstruct-Icons.svg#{$version-control}#BetConstruct-Icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="bc-i-"], [class*=" bc-i-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'BetConstruct-Icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.bc-i-lock:before {
  content: "\e930";
}
.bc-i-history:before {
  content: "\e903";
}
.bc-i-money-dollar:before {
  content: "\e92f";
}
.bc-i-favorite:before {
  content: "\e92e";
}
.bc-i-balance-management:before {
  content: "\e92d";
}
.bc-i-paper-dollar:before {
  content: "\e92b";
}
.bc-i-circle-dollar:before {
  content: "\e92c";
}
.bc-i-logout:before {
  content: "\e92a";
}
.bc-i-edit:before {
  content: "\e928";
}
.bc-i-delete:before {
  content: "\e929";
}
.bc-i-attention:before {
  content: "\e927";
}
.bc-i-info:before {
  content: "\e926";
}
.bc-i-corner:before {
  content: "\e921";
}
.bc-i-stream:before {
  content: "\e922";
}
.bc-i-card-football:before {
  content: "\e923";
}
.bc-i-half-time:before {
  content: "\e924";
}
.bc-i-team-t-shirt:before {
  content: "\e925";
}
.bc-i-graph:before {
  content: "\e91f";
}
.bc-i-head-t-head:before {
  content: "\e920";
}
.bc-i-field-soccer:before {
  content: "\e91a";
}
.bc-i-result:before {
  content: "\e91b";
}
.bc-i-field-basketball:before {
  content: "\e91c";
}
.bc-i-burger-2:before {
  content: "\e91d";
}
.bc-i-search:before {
  content: "\e91e";
}
.bc-i-wallet:before {
  content: "\e918";
}
.bc-i-user:before {
  content: "\e919";
}
.bc-i-streaming:before {
  content: "\e916";
}
.bc-i-statistics:before {
  content: "\e917";
}
.bc-i-facebook:before {
  content: "\e913";
}
.bc-i-linkedin:before {
  content: "\e914";
}
.bc-i-twitter:before {
  content: "\e915";
}
.bc-i-android:before {
  content: "\e910";
}
.bc-i-app-store:before {
  content: "\e911";
}
.bc-i-mobile:before {
  content: "\e912";
}
.bc-i-draw:before {
  content: "\e90f";
}
.bc-i-radio-selected:before {
  content: "\e90d";
}
.bc-i-radio-unselected:before {
  content: "\e90e";
}
.bc-i-eye-filled:before {
  content: "\e904";
}
.bc-i-eye:before {
  content: "\e90c";
}
.bc-i-time:before {
  content: "\e8b5";
}
.bc-i-call:before {
  content: "\e901";
}
.bc-i-casino:before {
  content: "\e902";
}
.bc-i-live-chat:before {
  content: "\e905";
}
.bc-i-burger-menu:before {
  content: "\e906";
}
.bc-i-message:before {
  content: "\e907";
}
.bc-i-notification:before {
  content: "\e908";
}
.bc-i-promotion:before {
  content: "\e909";
}
.bc-i-settings:before {
  content: "\e90a";
}
.bc-i-swatches:before {
  content: "\e90b";
}
.bc-i-pause:before {
  content: "\e034";
}
.bc-i-play:before {
  content: "\e037";
}
.bc-i-stop:before {
  content: "\e047";
}
.bc-i-checked:before {
  content: "\e5ca";
}
.bc-i-open-with:before {
  content: "\e89f";
}
.bc-i-settings1:before {
  content: "\e900";
}
.bc-i-apps:before {
  content: "\e5c3";
}
.bc-i-config:before {
  content: "\e869";
}
.bc-i-duplicate:before {
  content: "\e3e0";
}
.bc-i-add:before {
  content: "\e145";
}
.bc-i-remove:before {
  content: "\e15b";
}
.bc-i-check-box-checked:before {
  content: "\e834";
}
.bc-i-check-box-unchecked:before {
  content: "\e835";
}
.bc-i-arrow-back:before {
  content: "\e5c4";
}
.bc-i-arrow-forward:before {
  content: "\e5c8";
}
.bc-i-arrow-downward:before {
  content: "\e5db";
}
.bc-i-arrow-upward:before {
  content: "\e5d8";
}
.bc-i-small-arrow-left:before {
  content: "\e314";
}
.bc-i-small-arrow-right:before {
  content: "\e315";
}
.bc-i-small-arrow-down:before {
  content: "\e5cf";
}
.bc-i-small-arrow-up:before {
  content: "\e5ce";
}
.bc-i-arrow-drop-down-left:before {
  content: "\e5c5";
}
.bc-i-arrow-drop-down-right:before {
  content: "\e5c6";
}
.bc-i-arrow-drop-down-bottom:before {
  content: "\e5c7";
}
.bc-i-arrow-drop-down-up:before {
  content: "\e5c9";
}
.bc-i-close-remove:before {
  content: "\e5cd";
}
.bc-i-burger:before {
  content: "\e3c7";
}
.bc-i-DailyJackpots:before {
  content: "\ea51";
}
.bc-i-TopSlots:before {
  content: "\ea0f";
}
.bc-i-FlashGames:before {
  content: "\ea0c";
}
.bc-i-Slot777:before {
  content: "\ea0e";
}
.bc-i-New-2:before {
  content: "\ea0b";
}
.bc-i-ClassicSlots:before {
  content: "\e9b1";
}
.bc-i-LotteryGames:before {
  content: "\e9a4";
}
.bc-i-VideoBingo:before {
  content: "\e9a2";
}
.bc-i-OtherGames:before {
  content: "\e99f";
}
.bc-i-TableGames:before {
  content: "\e99b";
}
.bc-i-VideoPoker:before {
  content: "\e99c";
}
.bc-i-new-icon:before {
  content: "\e99d";
}
.bc-i-PopularGames:before {
  content: "\e986";
}
.bc-i-classic_slots_icon:before {
  content: "\e988";
}
.bc-i-CasualGames:before {
  content: "\e970";
}
.bc-i-New:before {
  content: "\e971";
}
.bc-i-ScratchGames:before {
  content: "\e972";
}
.bc-i-skill_games:before {
  content: "\e973";
}
.bc-i-TopGames:before {
  content: "\e975";
}
.bc-i-VideoSlots:before {
  content: "\e976";
}
.bc-i-Jackpots:before {
  content: "\e96c";
}
.bc-i-SlotMachine:before {
  content: "\e94e";
}
.bc-i-Vip:before {
  content: "\e94f";
}
.bc-i-Wheel-of-dice:before {
  content: "\e94a";
}
.bc-i-VirtualBetting:before {
  content: "\e931";
}
.bc-i-Star:before {
  content: "\e932";
}
.bc-i-Star2:before {
  content: "\e933";
}
.bc-i-Betting-bet:before {
  content: "\e934";
}
.bc-i-VSHorses:before {
  content: "\e6e7";
}
.bc-i-LiveDraw:before {
  content: "\77";
}
.bc-i-Search:before {
  content: "\e6d4";
}
.bc-i-Favorite-2:before {
  content: "\e6ac";
}
.bc-i-Favorite:before {
  content: "\e6df";
}
.bc-i-Favorite-outline:before {
  content: "\e6e1";
}
